import { OptionalTextareaField } from "@/lms/core/LmsFields";
import { Form, SubmitButton } from "@/components/form";
import { useForm } from "@tanstack/react-form";
import { ElementsResponse } from "@/lib/pb/types";
import { useUser } from "@/lib/store";
import { useAddCommentMutation } from "@/lib/pb/comment";


export function CommentAddForm({
  elementId,
  receiverId,
  classId,
}: {
  elementId: string;
  receiverId?: string;
  classId?: string;
}) {
  const user = useUser();
  const { mutateAsync: add, isPending } = useAddCommentMutation(
    receiverId,
    elementId,
  );
  const formOptions = {
    defaultValues: { content: "" },
    onSubmit: async ({ value, formApi }: { value: any; formApi: any }) => {
      if(value.content.trim() === "") return;
      const data = {
        elementId: elementId,
        senderId: user?.id,
        receiverId: receiverId ? receiverId : user?.id,
        content: value.content,
        classId: classId, //for filtering since enrollment is not stored
      };
      const result = await add({ data }).catch((e) => console.error(e));
      if (result) formApi.reset();
    },
  };
  
  const form = useForm(formOptions);
  return (
    <Form form={form}>
      <fieldset className="grid gap-2">
        <OptionalTextareaField
          form={form}
          name="content"
          placeholder={"Write a comment or question..."}
          rows="5"
        />
        <SubmitButton
          className="w-36 rounded-full bg-info hover:bg-info/80 h-9"
          label={<Label/>}
          form={form}
          disabled={isPending}
        />
      </fieldset>
    </Form>
  );
}

import {SendHorizontal} from "lucide-react";
function Label() {
  return (
    <div className="flex gap-2 items-center">
      <SendHorizontal/>
      <div>Comment</div>
    </div>
  )
}