import { useMemo } from "react";
import { PaginationTable, fuzzySort, fuzzyFilter } from "@/components/table";
import { getUrl } from "@/lib/pb";
import { UserFilesResponse } from "@/lib/pb/types";
import { ColumnDef } from "@tanstack/react-table";
import { format } from "date-fns";
import { formatBytes } from "@/lib/utils";
import { Copy, Trash2 } from "lucide-react";

import {
  CopyTextButton,
  DialogButton,
} from "@/components/format/Buttons";
import FileMimeIcon from "./FileMimeIcon";
import { ContentsProps, Header } from "@/components/format/Dialog";
import { useUser } from "@/lib/store";
import { ConfirmButtons } from "@/components/form";
import { useDeleteUserFileMutation } from "@/lib/pb/files";
import { ImageViewer } from "@/lms/elements/Image";

export const FILES_COLUMNS: ColumnDef<UserFilesResponse>[] = [
  {
    accessorFn: (row) => `${getUrl(row)}`,
    id: "thumbnail",
    header: () => <span>Thumbnail</span>,
    cell: (info) => {
      const type = info.row.original.type;
      const isImage = type.startsWith("image");
      const url = info.getValue() as string;
      // const url = value + (isImage ? "?thumb=100x100" : "");
      return (
        <div className="max-h-[50px] max-w-[50px]">
          {isImage ? (
            <ImageViewer
              className="bg-gray-500/50 border-2"
              url={url}
              useThumbnail={true}
            />
          ) : (
            <FileMimeIcon
              mimeType={type}
              className="h-[40px] w-[40px] mx-auto"
            />
          )}
        </div>
      );
    },
  },
  {
    accessorKey: "name",
    header: () => <span>Name</span>,
    meta: { filterVariant: "text", filterName: "Name" },
    filterFn: fuzzyFilter, //or just define with the function
    cell: (info) => {
      const value = info.getValue() as string;
      return <div className="max-w-64 line-clamp-2">{value}</div>
    }
  },
  {
    accessorFn: (row) => `${getUrl(row)}`,
    id: "url",
    header: () => <span>URL</span>,
    cell: (info) => {
      const value = info.getValue() as string;
      return (
        <div>
          <CopyTextButton
            text={value}
            label={
              <div className="line-clamp-2  tracking-tight font-normal text-xs max-w-0  md:max-w-16 lg:max-w-96">
                {value}
              </div>
            }
          />
        </div>
      );
    },
  },
  {
    accessorKey: "size",
    header: () => <span>Size</span>,
    cell: (info) => {
      const value = info.getValue() as number;
      return formatBytes(value);
    },
  },
  {
    accessorKey: "type",
    header: () => <span>Type</span>,
    meta: { filterVariant: "multiselect", filterName: "Type" },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: "created",
    header: () => "Date Uploaded",
    cell: (info) => {
      const value = info.getValue() as string | number | Date;
      return value ? format(value, "PPpp") : "";
    },
    sortingFn: "datetime",
  },
  {
    header: () => <span>Delete</span>,
    id: "action",
    cell: ({ row }) => (
      <div className="flex">
        <DialogButton
          icon={Trash2}
          item={row.original}
          contents={FileDeleteForm}
          className="mx-auto"
          variant="ghost"
        />
      </div>
    ),
  },
];

export const FILES_COLUMNS_USERS = [
  {
    accessorKey: "expand.userId.name",
    header: () => <span>User</span>,
    meta: { filterVariant: "text", filterName: "User" },
    filterFn: fuzzyFilter, //or just define with the function
  },
  ...FILES_COLUMNS];

export function UserFilesTable({
  files,
  isPending,
  refetch,
  showUser = false,
}: {
  files: UserFilesResponse[];
  isPending: boolean;
  refetch: () => void;
  showUser?: boolean;
}) {
  const columns = useMemo(
    () => (showUser ? FILES_COLUMNS_USERS : FILES_COLUMNS),
    [],
  );

  return (
    <div className="p-2 w-full m-auto">
      <PaginationTable
        data={files}
        columns={columns}
        sortEnabled={true}
        isPending={isPending}
        refetch={refetch}
        initialPageSize={10}
      />
    </div>
  );
}

export function FileDeleteForm({
  item,
  onComplete,
}: ContentsProps<UserFilesResponse>) {
  const user = useUser();
  const { mutateAsync: remove, isPending } = useDeleteUserFileMutation(user.id);

  return (
    <div>
      <Header
        title={"Confirm Delete File"}
        description="This action is permanent and cannot be undone. Are you sure you want to proceed?"
      />

      <div className="grid gap-2 p-6">
        <div className="flex gap-2">
          <FileMimeIcon mimeType={item.type} />
          {item.name}
        </div>
        <ConfirmButtons
          variant="destructive"
          onComplete={onComplete}
          onClick={async () => {
            if (isPending) return;
            await remove({ id: item.id }).catch((e) => console.error(e));
            onComplete();
          }}
          label="Delete File"
        />
      </div>
    </div>
  );
}
