import { createFileRoute } from "@tanstack/react-router";
import { Cheatsheet } from "@/lms/playground/Cheatsheet";
import DarkModeSwitch from "@/components/nav/DarkModeSwitch";
export const Route = createFileRoute("/_auth/course/$courseId/cheatsheet")({
  component: RouteComponent,
});

function RouteComponent() {
  return (
    <div>
      <div className="flex w-full justify-end">
        <DarkModeSwitch />
      </div>
      <Cheatsheet />
    </div>
  );
}
