import { FormFieldsProps } from "@/components/form/Form";
import { ScrollArea } from "@/components/ui/scroll-area";
import { CommonAdminFields } from "@/lms/core/LmsFields";
import { useEffect, useState } from "react";
import Types from "./Types";
import { staticPackageJson } from "../playground/templates/static";
import {
  react_vite_package_json,
  react_vite_config,
  react_eslint_config,
  react_index_html_v1,
} from "../playground/templates/react";
import { indexHtml } from "../playground/templates/phaser";
//const Id = "markdown";
const Name = "Paste JSON (v1)";

const Defaults = {
  type: "DYNAMIC",
};
// check ElementAddForm
function FormFields({
  isOriginal,
  isTranslate,
  form,
  setIsValid,
}: FormFieldsProps) {
  const locked = isOriginal || isTranslate;
  const [content, setContent] = useState("");
  const [element, setElement] = useState(null);

  useEffect(() => {
    navigator.clipboard.readText().then((clipText) => {
      setContent(clipText);
      try {
        const obj = JSON.parse(clipText);
        if (!obj) return;
        const { id, data, type, hidden, submissionRequired, translations } =
          obj;
        if (!type || !data) return;
        if (typeof type != "string" || typeof data != "object") return;
        const Type = Types[type];
        if (
          (Type || //if it is an lms2 type or check lms1 type
            type == "code-hike" ||
            type == "markdown-note" ||
            type == "markdown-2columns" ||
            type == "markdown-accordion" ||
            type == "external-link" ||
            type.endsWith("-playground")) &&
          obj.hidden !== undefined
        ) {
          const { isSubmitType, submissionRequired: ignore, ...rest } = data;
          form.setFieldValue("type", type);
          form.setFieldValue("active", hidden ? false : true);
          form.setFieldValue("submissionRequired", submissionRequired);
          form.setFieldValue("isSubmitType", isSubmitType ? true : false);

          let formattedData = formatData(type, rest);
          Object.keys(formattedData).forEach(function (key) {
            form.setFieldValue(key, formattedData[key]);
          });

          if (translations) {
            //add {data: }
            Object.keys(translations).forEach(function (key) {
              // need to format presentations
              let { isSubmitType, submissionRequired, ...translationData } =
                translations[key];
              translationData = formatData(type, translationData);
              translations[key] = { data: translationData };
            });
            form.setFieldValue("translations", translations);
          }
          setElement(obj);
          if (setIsValid) setIsValid(true);
        }
      } catch (e) {
        if (setIsValid) setIsValid(false);
        console.log(e);
      }
    });
  }, []);

  return (
    <div className="grid gap-4 py-4">
      <ScrollArea>
        <div className="border rounded-xl p-4 max-w-7xl text-muted-foreground text-sm max-h-96">
          {content}
        </div>
      </ScrollArea>
      {!element && (
        <div className="text-center text-destructive">JSON is not valid.</div>
      )}
      {!locked && <CommonAdminFields form={form}></CommonAdminFields>}
    </div>
  );
}

export function formatData(type: string, data: Record<string, any>) {
  let formattedData = data;

  if (type === "multiple-choice") {
    formattedData = formatMultipleChoice(data);
  } else if (type === "markdown-note") {
    formattedData = formatNote(data);
  } else if (type === "markdown-2columns") {
    formattedData = formatTwoColumns(data);
  } else if (type === "markdown-accordion") {
    formattedData = formatAccordion(data);
  } else if (type === "free-response") {
    formattedData = formatFreeResponse(data);
  } else if (type === "code-hike") {
    formattedData = formatCodeHike(data);
  } else if (type === "external-link") {
    formattedData = formatLink(data);
  } else if (type === "youtube") {
    formattedData = formatYoutube(data);
  } else if (type.endsWith("-playground")) {
    formattedData = formatPlayground(data, type);
  }
  return formattedData;
}

function formatPlayground(data: Record<string, any>, type: string) {
  const ret: Record<string, any> = {
    type: "code-playground",
    direction: "horizontal",
    enableComments: true,
    environment: "static",
    hint: "",
    instruction: "",
    readOnly: false,
    showEditor: true,
    showFileExplorer: false,
    showOpenInCodeSandbox: false,
    showPreview: true,
    showTerminal: false,
    splitPercent: 50,
  };

  const files: Record<string, any> = {};

  const isPreviewOnly = data.view == "previewOnly";

  let highlightKey = null;
  let highlightValue = null;
  Object.keys(data).forEach(function (key) {
    if (key == "question") {
      ret.instruction = data[key];
    } else if (key == "showCodeEditor") {
      ret.showEditor = data[key];
    } else if (key == "showConsole") {
      ret.showTerminal = data[key];
    } else if (key == "splitWidth") {
      ret.splitPercent = data[key];
    } else if (key == "width") {
      ret.width = isPreviewOnly ? "md" : "xxl";
    } else if (key == "height") {
      ret.height = isPreviewOnly ? "md" : "lg";
    } else if (key == "highlightMode") {
      //string, line, regex
      if (data[key] == "string") highlightKey = "highlightText";
      else if (data[key] == "line") highlightKey = "highlightLines";
      else if (data[key] == "regex") highlightKey = "highlightRegex";
    } else if (key == "highlightValue") {
      highlightValue = data[key];
    } else if (key == "visibleFiles" || key == "files" || key == "activeFile") {
      files[key] = data[key];
    } else ret[key] = data[key];
  });
  ret.files = files;
  if (highlightKey && highlightValue) {
    ret[highlightKey] = highlightValue;
  }
  //replace package.json!!!
  if (type == "react-playground") {
    ret.files.files["/package.json"] = { code: react_vite_package_json };
    ret.files.files["/vite.config.js"] = { code: react_vite_config };
    ret.files.files["/eslint.config.js"] = { code: react_eslint_config };
    // ret.files.files["/src"] = { code: ".emptyDir", hidden: true };
    if (ret.files.files["/index.js"]) {
      ret.files.files["/src/main.jsx"] = ret.files.files["/index.js"];
      delete ret.files.files["/index.js"];
    }
    if (ret.files.files["/App.js"]) {
      ret.files.files["/src/App.jsx"] = ret.files.files["/App.js"];
      delete ret.files.files["/App.js"];
    }
    if (ret.files.files["/styles.css"]) {
      if (ret.files.files["/styles.css"].code?.trim()) {
        ret.files.files["/src/styles.css"] = ret.files.files["/styles.css"];
      } else {
        ret.files.files["/src/styles.css"] = "main {}";
      }
      delete ret.files.files["/styles.css"];
    }
    ret.files.files["/index.html"] = { code: react_index_html_v1 };
    delete ret.files.files["/public/index.html"];
    ret.files.visibleFiles = ["/src/App.jsx"];
    ret.files.activeFile = "/src/App.jsx";
    ret.environment = "node";
    console.log(ret);
  } else {
    ret.files.files["/package.json"] = { code: staticPackageJson };
    if (ret.files.files["/index.html"]?.code?.includes("phaser")) {
      ret.files.files["/index.html"] = { code: indexHtml };
    }
  }

  return ret;
}

function formatAccordion(data: Record<string, any>) {
  const ret: Record<string, any> = {};

  ret.code = `<Accordion title='${data.accordianTitle}'>
${data.code}
</Accordion>`;
  ret.type = "markdown";
  return ret;
}

function formatNote(data: Record<string, any>) {
  const ret: Record<string, any> = {};

  ret.code = data.code;
  if (data.visibility === "admin") {
    ret.access = 900;
  } else if (data.visibility === "instructor") {
    ret.access = 500;
  } else if (data.visibility === "coach") {
    ret.access = 300;
  }
  ret.type = "markdown";
  return ret;
}

function formatTwoColumns(data: Record<string, any>) {
  const ret: Record<string, any> = {};

  ret.code = `<div className="grid grid-cols-2 gap-8">
  <div>
    ${data.code1}
  </div>
  <div>
    ${data.code2}
  </div>
</div>`;
  ret.type = "markdown";
  return ret;
}

function formatLink(data: Record<string, any>) {
  const ret: Record<string, any> = {};

  Object.keys(data).forEach(function (key) {
    if (key == "code") {
      ret.url = data[key];
    } else if (key == "color") {
      ret.variant = data[key] == "primary" ? "default" : data[key];
    } else ret[key] = data[key];
  });
  ret.type = "link";
  return ret;
}

function formatYoutube(data: Record<string, any>) {
  const ret: Record<string, any> = {};

  Object.keys(data).forEach(function (key) {
    if (key == "code") {
      ret.url = data[key];
    } else if (key == "start") {
      ret.startTime = data[key];
    } else if (key == "end") {
      ret.endTime = data[key];
    } else ret[key] = data[key];
  });
  return ret;
}
function formatCodeHike(data: Record<string, any>) {
  const ret: Record<string, any> = { type: "markdown" };

  Object.keys(data).forEach(function (key) {
    if (key == "code") {
      let res = data[key].replace(/<\/?CH\.Scrollycoding>/g, function (match) {
        return match === "<CH.Scrollycoding>"
          ? "<Scrollycoding>"
          : "</Scrollycoding>";
      });
      res = res.replace(/<\/?CH\.Spotlight>/g, function (match) {
        return match === "<CH.Spotlight>" ? "<Spotlight>" : "</Spotlight>";
      });
      res = res.replace(/<\/?CH\.Code>/g, "");
      res = res.replace(/^(#{1,6} )/gm, "$1!!steps ");
      res = res.replace(/``` *?(javascript|js) *?/gi, "```js !");
      res = res.replace(/---/g, "");
      ret[key] = res;
    } else ret[key] = data[key];
  });
  return ret;
}
function formatFreeResponse(data: Record<string, any>) {
  const ret: Record<string, any> = { row: 1 };

  Object.keys(data).forEach(function (key) {
    if (key == "height") return;
    else if (key == "removeWhiteSpaces") {
      ret.ignoreWhiteSpaces = data[key];
    } else ret[key] = data[key];
  });
  return ret;
}
function formatMultipleChoice(data: Record<string, any>) {
  const ret: Record<string, any> = {};
  const options: Record<string, any>[] = [];
  Object.keys(data).forEach(function (key: any) {
    if (!isNaN(key)) {
      options[parseInt(key)] = {
        explanation: "",
        text: data[key],
      };
    } else if (key == "answer") {
      ret.correctAnswer = data[key];
    } else {
      ret[key] = data[key];
    }
  });
  ret.options = options;
  return ret;
}

export default { Name, Defaults, FormFields };
