import CodeHike from "@/components/codehike/CodeHike";
import { DialogButton } from "@/components/format/Buttons";
import { Header } from "@/components/format/Dialog";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Lightbulb } from "lucide-react";

export function HintTrigger({ hint }: { hint: string }) {
  return (
    <DialogButton
      icon={Lightbulb}
      label="Hint"
      variant="ghost"
      contents={HintContent}
      className="ms-auto h-9 text-muted-foreground text-md hover:bg-warning rounded-full border border-warning"
      item={hint}
      preventDefault={true} //hint button is in the form, need to prevent default
    />
  );
}
export function HintContent({ item: code }: { item: string }) {
  return (
    <ScrollArea className="h-[87dvh]">
      <Header
        title={
          <div className="flex text-primary items-center gap-2">
            <Lightbulb className="h-4 w-4" /> Hint
          </div>
        }
      />
      <div className="flex flex-col">
        <CodeHike code={code} />
      </div>
    </ScrollArea>
  );
}
