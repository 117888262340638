import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuItem,
} from "@/components/ui/dropdown-menu";
import {
  useQuery,
  teamsListQuery,
  teamDetailQuery,
  enrollmentsListQuery,
} from "@/lib/pb/lms";

import {
  useCourse,
  useTeamFilter,
  setTeamFilter,
  useClassFilter,
} from "@/lib/store";
import { cn } from "@/lib/utils";
import { ChevronsUpDown } from "lucide-react";
import { useEffect } from "react";
import { groupBy } from "@/lib/pb";

export function TeamFilter({ className }: { className?: string }) {
  const classFilter = useClassFilter();
  const { data: teams, ...teamsQuery } = useQuery(
    teamsListQuery(classFilter?.id),
  );
  const teamFilter = useTeamFilter();
  const { data: team, ...teamQuery } = useQuery(
    teamDetailQuery(teamFilter?.id),
  );

  const course = useCourse();
  const filter = `courseId="${course.id}"${classFilter?.id ? `&&classId="${classFilter.id}"` : ""}`;
  const {
    data: enrollments,
    error: enrollmentsError,
    isPending: enrollmentsIsPending,
  } = useQuery(enrollmentsListQuery(filter));

  useEffect(() => {
    if (!classFilter || !teamFilter) {
      setTeamFilter(null);
    } else if (teamFilter.classId != classFilter.id) setTeamFilter(null);
  }, [classFilter]);

  useEffect(() => {
    if (enrollments && teams) {
      const teamMap = groupBy(enrollments, "teamId");
      teams.map((t) => (t.members = teamMap.get(t.id)));
    }
  }, [enrollments, teams]);

  if (!classFilter)
    return <div className="text-md font-normal">Select a class</div>;

  const placeholder = `Select a team `; //(${teams?.length ? teams.length : 0})
  const label = !team ? placeholder : team.name;

  return (
    <div className={cn("flex gap-2 items-center", className)}>
      <div className="flex gap-1 text-muted-foreground ">
        <div>Team: </div>
      </div>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="outline" className="h-8 w-60 text-muted-foreground">
            <Label label={label} />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuItem onClick={() => setTeamFilter(null)}>
            <div className="font-bold">{placeholder}</div>
          </DropdownMenuItem>
          {teams ? (
            teams.map((teamItem) => (
              <DropdownMenuItem
                key={teamItem.id}
                onClick={() => setTeamFilter(teamItem)}
              >
                <div className="grid">
                  <div>
                    {teamItem.name} (
                    {teamItem.members ? teamItem.members.length : 0})
                  </div>
                  <div className="text-xs text-muted-foreground">
                    {teamItem.members
                      ?.map((m) => m.expand.userId.name)
                      .join(", ")}
                  </div>
                </div>
              </DropdownMenuItem>
            ))
          ) : (
            <DropdownMenuItem>Loading...</DropdownMenuItem>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}

function Label({ label }: { label: string }) {
  return (
    <div className="flex items-center gap-2 w-full  ">
      <div className=" text-sm tracking-tight truncate">{label}</div>
      <ChevronsUpDown className="ms-auto h-4 w-4" />
    </div>
  );
}
