import CodeHike from "@/components/codehike/CodeHike";
import { FormFieldsProps } from "@/components/form/Form";
import { ScrollArea } from "@/components/ui/scroll-area";
import { ElementsResponse, SubmissionsResponse } from "@/lib/pb/types";

import {
  CommonAdminFields,
  OptionalTextareaField,
} from "@/lms/core/LmsFields";

const Id = "feedback";
const Name = "Feedback";

const Defaults = {
  question: "Let us know if you have questions, ideas, or found a mistake.",
  type: Id,
  isSubmitType: true, //need this for the SubmissionMenu
};

function FormFields({ isOriginal, isTranslate, form }: FormFieldsProps) {
  const locked = isOriginal || isTranslate;

  return (
    <div className="grid gap-4 py-4">
      <OptionalTextareaField
        form={form}
        name="question"
        label="Question/Prompt"
        disabled={isOriginal}
      />

      {!locked && <CommonAdminFields form={form}></CommonAdminFields>}
    </div>
  );
}

export function Submission({
  submission,
  element,
}: {
  submission: SubmissionsResponse;
  element: ElementsResponse;
}) {
  // const response = submission.data?.response;
  // const { answer, caseSensitive, ignoreWhiteSpaces } = element.data;
  if (!submission) return;

  return <div className="flex items-center gap-2">See Comment</div>;
}

export function SubmissionInfo({ items, element }) {
  return (
    <div className="grid border-2 h-full rounded-xl p-2">
      <ScrollArea className="mb-auto h-[200px] px-2">
      <div className="text-md font-bold">Question</div>
      <CodeHike item={element} code={element.data.question} />
      </ScrollArea>
    </div>
  );
}

export default { Id, Name, Defaults, FormFields, Submission, SubmissionInfo };
