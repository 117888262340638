import { DialogButton, TooltipSheetButton } from "@/components/format/Buttons";
import { Pencil, Trash2, UsersRoundIcon } from "lucide-react";
import { ROLES } from "@/lib/roles";
import { Header } from "@/components/format/Dialog";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
  useUser,
  useTeam,
  useTeamFilter,
  useCourse,
  useClassFilter,
} from "@/lib/store";
import { ClassFilter } from "../classes/ClassFilter";
import { TeamFilter } from "./TeamFilter";
import CodeHike from "@/components/codehike/CodeHike";
import { TeamDeleteForm, TeamEditForm } from "./TeamsTable";
import { teamDetailQuery, enrollmentsListQuery, useQuery } from "@/lib/pb/lms";
import { TeamAddForm } from "./TeamAdminCard";

export function TeamButton({ className }: { className?: string }) {
  const user = useUser();
  const isCoach = user?.role >= ROLES.coach;
  const isAdmin = user?.role == ROLES.administrator;
  const team = useTeam();
  if (!user) return;
  if (!isCoach && !team) return;
  return (
    <TooltipSheetButton
      icon={UsersRoundIcon}
      info="Team"
      variant="ghost"
      className={className}
      showInfo={true}
    >
      <div className="flex flex-col w-full h-screen gap-6">
        <Header title={<HeaderTeam isCoach={isCoach} />} description={""} />
        <ScrollArea className="">
          <div className="h-[92dvh] flex flex-col w-full gap-6">
            {isCoach ? <AdminTeamContent isAdmin={isAdmin} /> : <TeamContent />}
          </div>
        </ScrollArea>
      </div>
    </TooltipSheetButton>
  );
}

function HeaderTeam({ isCoach }: { isCoach: boolean }) {
  const classFilter = useClassFilter();
  return (
    <div className="flex gap-6 items-center">
      <div className="flex gap-2 items-center text-primary">
        <UsersRoundIcon /> <div>Team</div>
      </div>
      {isCoach && (
        <div className="flex ms-auto gap-4 items-center text-sm font-normal">
          <ClassFilter />

          {classFilter ? (
            <TeamFilter />
          ) : (
            <div className="w-72 text-center">Select a class</div>
          )}
        </div>
      )}
    </div>
  );
}

export function TeamContent() {
  //const course = useCourse();
  const team = useTeam();
  //if (!course) return null;

  if (!team) <div>No team</div>;

  return <TeamMarkdown team={team} />;
}

export function AdminTeamContent({ isAdmin }: { isAdmin: boolean }) {
  const classFilter = useClassFilter();
  const teamFilter = useTeamFilter();
  const course = useCourse();

  const filter = `courseId="${course.id}"${classFilter?.id ? `&&classId="${classFilter.id}"` : ""}${teamFilter?.id ? `&&teamId="${teamFilter.id}"` : ""}`;
  const {
    data: enrollments,
    error: enrollmentsError,
    isPending: enrollmentsIsPending,
  } = useQuery(enrollmentsListQuery(filter));


  const { data: team, isPending } = useQuery(teamDetailQuery(teamFilter?.id));
  //if (!course) return null;
  if (!teamFilter)
    return (
      <div className="w-full grid">
        {classFilter && (
          <div className="ms-auto">
            <TeamAddForm
              courseId={classFilter?.courseId}
              classId={classFilter?.id}
            />
          </div>
        )}
        <div className="text-center mt-10">No team selected</div>
      </div>
    );
  if (isPending) return <div>Loading...</div>;
  return (
    <div className="grid gap-4">
      {isAdmin && (
        <div className="flex gap-4">
          <DialogButton
            icon={Pencil}
            label="Edit Team"
            item={team}
            contents={TeamEditForm}
            fullScreen={true}
            variant="outline"
          />
          <DialogButton
            icon={Trash2}
            label="Delete Team"
            item={team}
            contents={TeamDeleteForm}
            variant="outline"
          />
          {classFilter && (
            <div className="ms-auto">
              <TeamAddForm
                courseId={classFilter.courseId}
                classId={classFilter.id}
              />
            </div>
          )}
        </div>
      )}
      <div className="flex gap-4 px-4">
        <div className="font-bold">Team Members:</div>
        {enrollments?.map((item) => {
          return (
            <div key={item.id} className="bg-primary text-primary-foreground px-4 rounded-full">
              {item.expand.userId.name}
            </div>
          );
        })}
      </div>
      <TeamMarkdown team={team} />
    </div>
  );
}

function TeamMarkdown({ team }: { team: TeamsResponse }) {
  if (!team) return;
  return (
    <div className="grid gap-4 mx-4 mt-4">
      <div className="text-3xl font-bold tracking-tight">{team.name}</div>
      <hr />
      <CodeHike className="mt-4" code={team.markdown} />
    </div>
  );
}
