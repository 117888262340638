import CodeHike from "@/components/codehike/CodeHike";
import { ElementTitle } from "@/lms/element/ElementTitle";
import { ElementsResponse } from "@/lib/pb/types";
import { OptionalTextareaField } from "@/lms/core/LmsFields";
import { Form } from "@/components/form";
import { useForm } from "@tanstack/react-form";
import { CircleHelp, RotateCw, User } from "lucide-react";
import {
  useSubmit,
  useSubmissionDetail,
  submissionElementQuery,
} from "@/lib/pb/submissions";

import { useEnrollment, useTeam, useUser } from "@/lib/store";
import { BasicMarkdown } from "@/components/format/BasicMarkdown";
import { ElementSubmissionNav } from "@/lms/element/ElementSubmissonNav";
import { CorrectIndicator } from "../element/CorrectIndicator";

export default function FreeResponse({
  element,
  children,
}: {
  element: ElementsResponse;
  children?: React.ReactNode;
}) {
  const team = useTeam();
  if (!element) return null;

  const { data } = element;
  if (!data) return null;

  return (
    <div className="flex w-full justify-center">
      <div className="w-full max-w-3xl flex flex-col gap-2 ">
        <ElementTitle icon={CircleHelp} title={"Free Response"}>
          {children}
        </ElementTitle>
        <div className="flex flex-col gap-3 bg-accent/40 px-4 py-2 rounded-md border border-primary/50">
          <CodeHike item={element} code={data.question} />
          <ResponseForm element={element} data={data} />
          {data.enableTeamView && team && (
            <TeamView team={team} element={element} />
          )}
        </div>
      </div>
    </div>
  );
}

export function formatAnswer(
  ans: string,
  caseSensitive: string,
  ignoreWhiteSpaces: string,
) {
  if (!ans) return;
  ans = ans.trim();
  ans = caseSensitive ? ans : ans.toLowerCase();
  ans = ignoreWhiteSpaces ? ans.replace(/\s/g, "") : ans;
  return ans;
}

export function getAnswers(
  elementAnswer: string,
  caseSensitive: string,
  ignoreWhiteSpaces: string,
) {
  return elementAnswer
    ? elementAnswer
        ?.split("||")
        .map((ans: string) =>
          formatAnswer(ans, caseSensitive, ignoreWhiteSpaces),
        )
    : null;
}

function ResponseForm({
  element,
  data,
}: {
  element: ElementsResponse;
  data: any;
}) {
  const user = useUser();
  const enrollment = useEnrollment();
  const { data: submission } = useSubmissionDetail(
    element.id,
    element.sectionId,
    enrollment?.id,
    element.submissionRequired,
  );
  const { submit, isPending: submitPending } = useSubmit(
    element.id,
    element.sectionId,
    enrollment?.id,
  );
  const formOptions = {
    defaultValues: { response: "" },
    //defaultValues: { response: submission?.data?.response },
    //TODO: Create a defaultSubmit function
    onSubmit: async ({ value, formApi }: { value: any; formApi: any }) => {
      value.response = value.response.trim();
      const data = {
        elementId: element.id,
        enrollmentId: enrollment?.id,
        teamId: enrollment?.teamId,
        name: user?.name,
        attempts: submission ? submission.attempts + 1 : 1,
        data: value,
        complete: answers
          ? answers?.includes(
              formatAnswer(value.response, caseSensitive, ignoreWhiteSpaces),
            )
          : true,
      };

      if (!submit) throw new Error("no submit function");
      const result = await submit(data, submission?.id);
      if (result) formApi.reset();
    },
  };

  const form = useForm(formOptions);
  const formResponse = form.useStore((state) => state.values.response).trim();

  const { answer, caseSensitive, ignoreWhiteSpaces } = element.data;

  const answers = answer
    ? answer
        ?.split("||")
        .map((ans: string) =>
          formatAnswer(ans, caseSensitive, ignoreWhiteSpaces),
        )
    : null;

  const submittedResponse = submission?.data?.response;
  const correct = answers?.includes(
    formatAnswer(submittedResponse, caseSensitive, ignoreWhiteSpaces),
  );

  const unchanged =
    !formResponse ||
    formResponse?.length == 0 ||
    formResponse == submittedResponse;

  return (
    <Form form={form}>
      <fieldset>
        <div className="text-xs text-muted-foreground mb-1">Your response:</div>
        {submission && <SubmittedResponse response={submittedResponse} />}

        <OptionalTextareaField
          form={form}
          name="response"
          placeholder={
            submission
              ? "Response submitted. Type here to submit a new response..."
              : "Type your response here..."
          }
          rows={data.rows}
        />

        <ElementSubmissionNav
          item={element}
          form={form}
          disabled={submitPending || unchanged}
          submission={submission}
          hint={data.hint}
          className="mt-3"
        >
          {unchanged && answers && submission && (
            <CorrectIndicator correct={correct} />
          )}
        </ElementSubmissionNav>
      </fieldset>
    </Form>
  );
}

// var response2 = caseSensitive ? response : response.toLowerCase();
// response2 = removeWhiteSpaces ? response2.replace(/\s/g, '') : response2;
// if (answer) {
//   if (answer.includes('||')) {
//     var list = answer.split('||').map(e => e.trim());
//     if (!caseSensitive) list = list.map(e => e.toLowerCase());
//     if (removeWhiteSpaces) list = list.map(e => e.replace(/\s/g, ''));
//     set.correct = list.includes(response2)
//   }
//   else {
//     var answer2 = caseSensitive ? answer : answer.toLowerCase();
//     answer2 = removeWhiteSpaces ? answer2.replace(/\s/g, '') : answer2;
//     set.correct = response2 === answer2;
//   }
// }

function SubmittedResponse({ response }: { response: string }) {
  return (
    <div className="mb-2">
      <div className="border px-2 pt-1 pb-2 bg-background/75 rounded-md text-sm">
        <BasicMarkdown className="ps-2 tracking-tight" code={response} />
      </div>
    </div>
  );
}

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { TooltipButton } from "@/components/format/Buttons";
import { useState, useEffect } from "react";
import { useQuery } from "@/lib/pb/lms";

function TeamView({
  team,
  element,
}: {
  team: TeamsResponse;
  element: ElementsResponse;
}) {
  const enrollment = useEnrollment();
  const [teamSubmissions, setTeamSubmissions] = useState<SubmissionsResponse[]>(
    [],
  );

  //fetch team submissions
  const {
    data: submissions,
    refetch: refetchSubmissions,
    isPending,
  } = useQuery(submissionElementQuery(element.id, null, team.id));

  useEffect(() => {
    if (!submissions) return;
    const array = [...submissions.values()];
    setTeamSubmissions(
      enrollment
        ? array.filter((submission) => submission.enrollmentId != enrollment.id)
        : array,
    );
  }, [submissions]);

  if (!element) return null;

  return (
    <Accordion collapsible className="" type="single" defaultValue="">
      <AccordionItem value="item-1" className={`bg-info/50 rounded-lg border`}>
        <AccordionTrigger className={`flex p-3 rounded-xl font-bold text-xl`}>
          Team Submissions - {`${team.name} (${teamSubmissions.length})`}
        </AccordionTrigger>
        <AccordionContent className="flex flex-col m-1 px-4 rounded-sm">
          <TooltipButton
            variant="outline"
            className="ms-auto p-2 text-muted-foreground mb-2"
            onClick={refetchSubmissions}
            disabled={isPending}
            info="Refresh Submissions"
          >
            <RotateCw className="h-4 w-4" /> Refresh
          </TooltipButton>
          <div className="grid gap-4">
            {teamSubmissions.map((submission) => {
              return (
                <div className="grid w-full" key={submission.id}>
                  <div className="flex text-lg gap-4">
                    <User /> {submission?.name}
                  </div>
                  <div className="grid w-full">
                     {submission && <SubmittedResponse response={submission?.data?.response} />}
                  </div>
                </div>
              );
            })}
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}
