import CodeHike from "@/components/codehike/CodeHike";
import {
  ElementsResponse,
  RecordIdString,
  SubmissionsResponse,
  TeamsResponse,
} from "@/lib/pb/types";
import { Playground, SandpackFiles } from "@/lms/playground/Playground";
import { RotateCw, Keyboard, User } from "lucide-react";
import { useEnrollment, useTeam, useUser } from "@/lib/store";
import { Form } from "@/components/form";
import { useForm } from "@tanstack/react-form";
import { ElementSubmissionNav } from "@/lms/element/ElementSubmissonNav";
import {
  useSubmit,
  useSubmissionDetail,
  submissionElementQuery,
} from "@/lib/pb/submissions";
import { cn } from "@/lib/utils";
import { Widths } from "@/lib/sizes";
import { GenericInputField } from "@/components/form/CustomFields";
import { memo, useCallback, useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import { ElementTitle } from "@/lms/element/ElementTitle";

import { useAddSubmissionHistoryMutation } from "@/lib/pb/submission-history";
import { SubmissionHistory } from "../submissions/SubmissionHistory";

import { useQuery } from "@/lib/pb/lms";

export default function CodePlayground({
  element,
  children, // <ElementSubmissionAdminNav element={element} />
}: {
  element: ElementsResponse;
  children?: React.ReactNode;
}) {
  //const data = element.original ? element.original.data : element.data;

  //get translation from element.data but keep original
  if (element.original && element.data) {
    const dataCopy = { ...element.data };
    element.data = {
      ...element.original.data,
      files: dataCopy.files,
      hint: dataCopy.hint,
      instruction: dataCopy.instruction,
    };
  }

  // const { instruction, ...playgroundProps } = element.data;
  //const instruction = element?.data?.instruction;
  return (
    <div className="grid w-full gap-2 ">
      <div className="w-full max-w-3xl flex flex-col gap-2 mx-auto ">
        {element.isSubmitType && (
          <ElementTitle
            icon={Keyboard}
            title={"Code Playground"}
          ></ElementTitle>
        )}
        {element.data?.instruction && (
          <CodeHike item={element} code={element.data.instruction} />
        )}
        <div className="flex w-full">
          <div className="mx-auto rounded-md  pt-2 px-4">
            {children}
          </div>
        </div>
      </div>
      <div
        className={cn(
          "mx-auto w-full bg-accent/40 rounded-md border border-primary/50 hover:border-primary",
          Widths[element.data?.width as keyof typeof Widths],
        )}
      >
        {element.isSubmitType ? (
          <CodePlaygroundForm element={element} />
        ) : (
          <BasicCodePlayground element={element} />
        )}
      </div>
    </div>
  );
}

function BasicCodePlayground({ element }: { element: ElementsResponse }) {
  const { files, instruction, ...playgroundProps } = element.data as Record<
    string,
    any
  >;
  const [fullScreen, setFullScreen] = useState(false);
  return (
    <div className={fullScreen ? " fixed inset-0 z-10" : "relative"}>
      <MemoizedPlayground
        {...playgroundProps}
        baseFiles={files}
        mountPoint={element.id}
        fullScreen={fullScreen}
        setFullScreen={setFullScreen}
      />
    </div>
  );
}

//const MemoizedCodePlaygroundForm = memo(CodePlaygroundForm);
const MemoizedPlayground = memo(Playground); //because of dirty state

function CodePlaygroundForm({ element }: { element: ElementsResponse }) {
  const data = element.data;
  const {
    files,
    instruction,
    enableHistory,
    enableTeamView,
    ...playgroundProps
  } = element.data as Record<string, any>; //default data
  const team = useTeam();

  const [fullScreen, setFullScreen] = useState(false);
  const [dirty, setDirty] = useState(false); //this triggers rerender!
  const [baseFiles, setBaseFiles] = useState(files);

  const user = useUser();
  const enrollment = useEnrollment(); //stale time infinity

  const { submit, isPending: submitPending } = useSubmit(
    element.id,
    element.sectionId,
    enrollment?.id,
  );

  //CHECK SINGLE SUBMISSION (User) refech could cause loss of work
  const { data: submission, isPending: isFetchingSubmission } =
    useSubmissionDetail(
      element.id,
      element.sectionId,
      enrollment?.id,
      element.submissionRequired,
    );

  const { mutateAsync: addHistory, isPending: addPending } =
    useAddSubmissionHistoryMutation(submission?.id);

  function changeFiles(newFiles: any) {
    setBaseFiles(newFiles);
    setDirty(false);
  }

  //Resetting to submission file data.........
  useEffect(() => {
    if (submission && submission.data && submission.data.files) {
      // console.log("Resetting to submission file data............", data.files);
      changeFiles({ ...submission.data.files });
    }
  }, [submission]); //sets base file to user submission.

  //FILES CHANGE (Admin Edit)
  useEffect(() => {
    if (submission || !data) return; //don't replace submission with admin edit
    //console.log("base files changed", data.files);
    changeFiles({ ...data.files });
  }, [data]);

  const formOptions = {
    defaultValues: { files }, //when use basefile i get an error
    //defaultValues: { response: submission?.data?.response },
    //TODO: Create a defaultSubmit function
    onSubmit: async ({ value, formApi }: { value: any; formApi: any }) => {
      const attempts = submission ? submission.attempts + 1 : 1;
      value.name = user?.name; //add name for team submission
      const data = {
        elementId: element.id,
        enrollmentId: enrollment?.id,
        teamId: enrollment?.teamId,
        name: user?.name,
        attempts,
        data: value,
        complete: true,
      };

      if (!submit) throw new Error("no submit function");
      const submissionResult = await submit(data, submission?.id);

      // if the submission is successfull
      if (submissionResult) {
        // check if history switch is enabled
        if (enableHistory) {
          const data = {
            submissionId: submissionResult.id,
            version: attempts,
            data: value,
          };
          await addHistory({ data }).catch((e) => console.error(e));
        }
        formApi.reset();
      }
    },
  };

  const form = useForm(formOptions);
  //const formFiles = form.useStore((state) => state.values.files);

  const updateParentForm = useCallback(
    (newFiles: SandpackFiles) => {
      const { files, activeFile, visibleFiles } = newFiles as SandpackFiles;

      const formFiles = form.getFieldValue("files");
      form.setFieldValue("files", {
        ...formFiles,
        files: files ?? formFiles.files,
        activeFile: activeFile ?? formFiles.activeFile,
        visibleFiles: visibleFiles ?? formFiles.visibleFiles,
      });

      let foundDirtyFile = false;
      //what happens if user closes visible files..
      const original = submission?.data?.files
        ? submission.data.files
        : data.files;
      if (files) {
        if (haveSameKeys(files, original.files)) {
          visibleFiles?.map((name) => {
            if (isFileDirty(files[name], original?.files[name])) {
              //console.log("DIRTY: ", files[name], original?.files[name]);
              foundDirtyFile = true;
            }
          });
        } else {
          // dont have the same file names then dirty
          foundDirtyFile = true;
        }
        setDirty(foundDirtyFile);
      }
    },
    [submission, data],
  );

  const btnStyle =
    "h-9 flex rounded-full text-muted-foreground hover:text-foreground text-xs w-20 text-wrap";
  return (
    <div className={fullScreen ? " fixed inset-0 z-10" : "relative"}>
      <MemoizedPlayground
        {...playgroundProps}
        key={playgroundProps.splitPercent}
        baseFiles={baseFiles}
        updateParentForm={updateParentForm}
        //keeping mount point the same
        mountPoint={element.id}
        fullScreen={fullScreen}
        setFullScreen={setFullScreen}
      />

      <Form key={element.id} form={form}>
        <fieldset>
          <GenericInputField
            form={form}
            className="hidden"
            name="files"
            type="hidden"
          />
          <ElementSubmissionNav
            item={element}
            form={form}
            disabled={submitPending || !dirty}
            submission={submission}
            hint={data.hint}
            instructions={data.instruction}
            className="px-4 py-3 border-t-2 bg-background"
          >
            {enableHistory && (
              <SubmissionHistory
                submissionId={submission?.id}
                dirty={dirty}
                changeFiles={changeFiles}
              />
            )}
            <Button
              variant="ghost"
              className={btnStyle}
              onClick={(e) => {
                e.preventDefault();
                changeFiles({ ...files });
              }}
            >
              <RotateCw className="h-4 w-4 pe-1" />
              Reset
            </Button>
            {submission && !enableHistory && (
              <Button
                variant="ghost"
                className={btnStyle}
                onClick={(e) => {
                  e.preventDefault();
                  changeFiles({ ...submission.data?.files });
                }}
              >
                Restore to Saved
              </Button>
            )}
          </ElementSubmissionNav>
        </fieldset>
      </Form>
      {dirty && (
        <div className="flex ms-4 px-2 absolute bottom-[3.5rem] z-10 left-1 text-primary-foreground text-xs bg-primary border-primary border rounded-xl animate-bounce">
          You have unsaved changes.
        </div>
      )}
      {enableTeamView && team && <TeamView team={team} element={element} />}
    </div>
  );
}

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { TooltipButton } from "@/components/format/Buttons";

function TeamView({
  team,
  element,
}: {
  team: TeamsResponse;
  element: ElementsResponse;
}) {
  const enrollment = useEnrollment();
  const [teamSubmissions, setTeamSubmissions] = useState<SubmissionsResponse[]>(
    [],
  );

  //fetch team submissions
  const {
    data: submissions,
    refetch: refetchSubmissions,
    isPending,
  } = useQuery(submissionElementQuery(element.id, null, team.id));

  useEffect(() => {
    if (!submissions) return;
    const array = [...submissions.values()];
    setTeamSubmissions(
      enrollment
        ? array.filter((submission) => submission.enrollmentId != enrollment.id)
        : array,
    );
  }, [submissions]);

  if (!element) return null;
  //console.log("1======",element)
  return (
    <Accordion collapsible className="" type="single" defaultValue="">
      <AccordionItem value="item-1" className={`bg-info/50 rounded-lg border`}>
        <AccordionTrigger className={`flex p-3 rounded-xl font-bold text-xl`}>
          Team Submissions - {`${team.name} (${teamSubmissions.length})`}
        </AccordionTrigger>
        <AccordionContent className="flex flex-col m-1 px-4 rounded-sm">
          <TooltipButton
            variant="outline"
            className="ms-auto p-2 text-muted-foreground mb-2"
            onClick={refetchSubmissions}
            disabled={isPending}
            info="Refresh Submissions"
          >
            <RotateCw className="h-4 w-4" /> Refresh
          </TooltipButton>
          <div className="grid gap-4">
            {teamSubmissions.map((submission) => {
              return (
                <div className="grid w-full" key={submission.id}>
                  <div className="flex text-lg gap-4">
                    <User /> {submission?.name}
                  </div>
                  <div className="grid w-full border-4">
                    <Playground
                      key={submission.id} //remounts entire playground
                      baseFiles={submission?.data?.files}
                      canEditFiles={false}
                      showFileExplorer={element.data.showFileExplorer}
                      mountPoint={submission.id}
                      showTerminal={false}
                      showOpenInCodeSandbox={false} //form in form error
                      environment={element.data.environment}
                      height={
                        element.original
                          ? element.original.data.height
                          : element.data.height
                      }
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}

function isFileDirty(f1: any, f2: any) {
  if (!f1 || !f2) return false;
  // console.log(f1, f2);
  const f1Code = typeof f1 == "string" ? f1 : f1.code;
  const f2Code = typeof f2 == "string" ? f2 : f2.code;
  return f1Code.trim() != f2Code.trim();
}

function haveSameKeys(obj1, obj2) {
  //console.log("haveSameKeys", obj1, obj2);
  const keys1 = Object.keys(obj1).sort();
  const keys2 = Object.keys(obj2).sort();

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let i = 0; i < keys1.length; i++) {
    if (keys1[i] !== keys2[i]) {
      return false;
    }
  }

  return true;
}
