import { useState } from "react";
import { PlusCircle, Trash2 } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Textarea } from "@/components/form/Input";

//==============================================

import { FormFieldsProps } from "@/components/form/Form";
import { BasicMarkdown } from "@/components/format/BasicMarkdown";
import { ElementsResponse, SubmissionsResponse } from "@/lib/pb/types";

import {
  CommonAdminFields,
  MarkdownField,
  SubmissionRequiredSwitchField,
  EnableCommentsSwitchField,
} from "@/lms/core/LmsFields";
import { cn } from "@/lib/utils";
import { getCorrectAnswer } from "./MultipleChoice";
import CodeHike from "@/components/codehike/CodeHike";
import { ScrollArea } from "@/components/ui/scroll-area";

const Id = "multiple-choice";
const Name = "Multiple Choice";

const Defaults = {
  question: "",
  hint: "",
  type: Id,
  isSubmitType: true,
  submissionRequired: true,
  enableComments: true,
  options: [
    { text: "", explanation: "" },
    { text: "", explanation: "" },
  ],
  correctAnswer: -1,
};

function FormFields({ isOriginal, isTranslate, form }: FormFieldsProps) {
  const locked = isOriginal || isTranslate;
  const correctAnswer = form.useStore((state) => state.values.correctAnswer);

  return (
    <div className="grid gap-4 py-4 px-1">
      <MarkdownField
        form={form}
        name="question"
        label="Question"
        disabled={isOriginal}
        vertical={!!locked}
        size="xs"
      />
      <Label>Choices</Label>
      <form.Field name="options" mode="array">
        {(field) => {
          const options = field.state.value;
          return (
            <RadioGroup
              value={correctAnswer}
              onValueChange={(value) =>
                form.setFieldValue("correctAnswer", value)
              }
            >
              {options.map((_, index: number) => {
                return (
                  <div key={index} className="flex gap-2 items-center">
                    <div
                      key={index}
                      className="flex items-center gap-4 ms-6 w-full"
                    >
                      {!locked && (
                        <RadioGroupItem
                          value={index.toString()}
                          id={`option-${index}`}
                        />
                      )}
                      <form.Field name={`options[${index}].text`}>
                        {(subField: any) => {
                          const option = subField.state.value;
                          return (
                            <Textarea
                              value={option}
                              onChange={(e: any) =>
                                subField.handleChange(e.target.value)
                              }
                              placeholder={`Option ${index + 1}`}
                              className={cn(
                                "resize-y",
                                index == correctAnswer ? "border-success" : "",
                              )}
                            />
                          );
                        }}
                      </form.Field>

                      <form.Field name={`options[${index}].explanation`}>
                        {(subField: any) => {
                          const option = subField.state.value;
                          return (
                            <Input
                              value={option}
                              onChange={(e: any) =>
                                subField.handleChange(e.target.value)
                              }
                              placeholder={`Explanation ${index + 1} (optional)`}
                            />
                          );
                        }}
                      </form.Field>
                    </div>
                    {!locked && options.length > 2 && (
                      <Button
                        type="button"
                        variant="ghost"
                        onClick={() => {
                          //fix correct answer index on delete
                          if (correctAnswer == index) {
                            form.setFieldValue("correctAnswer", -1);
                          } else if (correctAnswer > index) {
                            form.setFieldValue(
                              "correctAnswer",
                              correctAnswer - 1,
                            );
                          }
                          field.removeValue(index);
                        }}
                        className="text-foreground "
                      >
                        <Trash2 className="h-4 w-4" />
                        <span className="sr-only">
                          Remove option {index + 1}
                        </span>
                      </Button>
                    )}
                  </div>
                );
              })}

              {!locked && (
                <div className="flex gap-4 mt-2">
                  <Button
                    variant="secondary"
                    onClick={(e) => {
                      e.preventDefault();
                      form.setFieldValue("correctAnswer", -1);
                    }}
                    type="button"
                    disabled={correctAnswer === -1}
                  >
                    Clear Answer
                  </Button>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      field.pushValue({ text: "", explanation: "" });
                    }}
                    type="button"
                    variant="outline"
                    className="w-full max-w-xl"
                  >
                    Add Choice
                  </Button>
                </div>
              )}
            </RadioGroup>
          );
        }}
      </form.Field>
      <MarkdownField
        form={form}
        name="hint"
        label="Hint"
        disabled={isOriginal}
        vertical={!!locked}
        size="sm"
      />
      {!locked && (
        <CommonAdminFields form={form}>
          <SubmissionRequiredSwitchField form={form} />
          <EnableCommentsSwitchField form={form} />
        </CommonAdminFields>
      )}
    </div>
  );
}

export function Submission({
  submission,
  element,
}: {
  submission: SubmissionsResponse;
  element: ElementsResponse;
}) {
  //code={submission?.data?.response}
  if (!submission) return <div>No Submission Found!</div>;
  const response = submission.data?.response;
  const correctAnswer = getCorrectAnswer(element.data?.correctAnswer);

  const correct = response == correctAnswer; //double equal instead of triple

  return (
    <div className="flex gap-2 items-center ">
      <div className="font-bold text-muted-foreground">{response}</div>
      <BasicMarkdown
        className={cn(
          "border rounded-sm px-2 min-w-32",
          correctAnswer >= 0
            ? correct
              ? "border-success"
              : "border-destructive"
            : "border-muted-foreground",
        )}
        code={element.data?.options?.[response]?.text}
      />
    </div>
  );
}

export function SubmissionInfo({ items, element }) {
  return (
    <div className="grid border-2 h-full rounded-xl p-2">
      <ScrollArea className="mb-auto h-[200px] px-2">
        <div className="text-md font-bold">Question</div>
        <CodeHike item={element} code={element.data.question} />
      </ScrollArea>
    </div>
  );
}

export default { Id, Name, Defaults, FormFields, Submission, SubmissionInfo };
