import {
  useMutation,
  useQueryClient,
  queryOptions,
} from "@tanstack/react-query";
import pb, { pbUpdate } from "@/lib/pb";
import { useToastMutation, showSuccess, showError } from "@/lib/toast";
import { useNavigate, useRouter } from "@tanstack/react-router";
import { ROLES } from "@/lib/roles";
const userKey = ["authenticated-user"];

export interface Credentials {
  email: string;
  password: string;
}

export interface UserBase {
  email: string;
  password: string;
  passwordConfirm?: string;
  name: string;
  lastName?: string;
  role?: number;
  emailVisibility?: boolean;
}

async function refreshUser() {
  // do not refresh if user is not authenticated
  if (!pb.authStore.isValid) return null;

  const collection: any = pb.authStore.isAdmin
    ? pb.admins
    : pb.collection("users");
  // returns user record
  return await collection.authRefresh().catch((e: Error) => {
    showError(e, "Failed to refresh user");
    return null;
  });
}
//THis SHOULD BE USE MUTATION AND NOT USE QUERY and ShoUld be done in root useEffect
//TODO: Maybe dont refresh user too often otherwise page might rerender too often
export const refreshAuthQuery = queryOptions({
  queryKey: userKey,
  queryFn: refreshUser,
  staleTime: 1000 * 60 * 60 * 20, //one day
});

//====== Auth with Provider =======
export function useAuthWithProvider() {
  return useClearMutation(
    authWithProvider,
    userKey,
    "Login",
    "Welcome back! 👋",
  );
}
export async function authWithProvider() {
  const authData = await pb
    .collection("users")
    .authWithOAuth2({ provider: "google" });
  const meta = authData.meta;
  console.log(authData);
  if (meta?.isNew) {
    // this is a sign up
    const userData = {
      name: "",
      lastName: "",
      role: ROLES.student,
      emailVisibility: true,
    };

    if (meta.rawUser) {
      userData.name = meta.rawUser.name;
      userData.lastName = meta.rawUser.lastName;
    } else {
      const parts = meta.name.split(" ");
      userData.name = parts[0];
      userData.lastName = parts.slice(1).join(" ");
    }
    console.log("update", authData.record.id, userData)
    const res =  await pb.collection("users").update(authData.record.id, userData);
    await pb.collection("users").authRefresh(); //roll authToken
    return res;
  }

  return authData;
}

function getUserId() {
  //this is not a store, so it doesnt trigger re-renders
  return pb.authStore?.record?.id;
}

async function editUserFn(data: Record<string, any>) {
  const userId = getUserId();
  console.log("editUserFn", userId);
  const res = await pbUpdate("users", userId, data);
  await pb.collection("users").authRefresh(); //roll authToken
  return res;
}

export const useEditUserMutation = () =>
  useToastMutation(editUserFn, userKey, "Profile Update");

export const useEditUser = () => useEditUserMutation().mutateAsync;

// ========= REGISTER  ===============
export async function registerFn(data: UserBase) {
  data.passwordConfirm = data.password;
  data.role = data.role || ROLES.student;
  data.emailVisibility = true;
  const response = await pb
    .collection("users")
    .create(data)
    .then(async () => await loginFn(data));
  return response;
}

export const useRegisterMutation = () =>
  useClearMutation(registerFn, userKey, "Sign Up", `New accound created`);

export const useRegister = () => useRegisterMutation().mutateAsync;

// ========= ADMIN LOGIN (NOT USED) ===============

async function adminLoginFn({ email, password }: Credentials) {
  const response = await pb.admins.authWithPassword(email, password);
  return response.admin; //strips the token from response
}

export const useAdminLoginMutation = () =>
  useClearMutation(adminLoginFn, userKey, "Admin Login", "Welcome back! 👋");

export const useAdminLogin = () => useAdminLoginMutation().mutateAsync;

// ========= USER LOGIN ===============
async function loginFn({ email, password }: Credentials) {
  const response = await pb
    .collection("users")
    .authWithPassword(email, password);
  //const user = response.record;
  //const res2 = await pb.collection('users').update(user.id, {lastLogin: Date.now()})
  return response.record; //record is the user details
}

export const useLoginMutation = () =>
  useClearMutation(loginFn, userKey, "Login", "Welcome back! 👋");

export const useLogin = () => useLoginMutation().mutateAsync;

// ========= CHANGE PASSWORD (TODO) ===============
async function changePasswordFn({ id, set }: { id: string; set: Object }) {
  return await pb.collection("users").update(id, set);
}

export function useChangePassword(options) {
  return useMutation({
    mutationFn: changePasswordFn,
    ...options,
    onError: (err, ...rest) => {
      //      handleError(err, "Change Password Error");
    },
    onSuccess: (user, ...rest) => {
      console.log(user);
    },
  });
}
import { QueryKey, MutationFunction } from "@tanstack/react-query";

export function useClearMutation<TVariables, TResult>(
  mutationFn: MutationFunction<TResult, TVariables>,
  key?: QueryKey,
  action?: string,
  message?: string,
) {
  const queryClient = useQueryClient();

  return useMutation<TResult, Error, TVariables>({
    mutationFn: mutationFn,
    onError: (e) => {
      showError(e, `${action} Failed`);
    },
    onSuccess: (mutationResult: TResult, variables: TVariables) => {
      showSuccess(`${action} Successful`, message);
      queryClient.invalidateQueries(); //because courses wasnt full refetching
      //console.log("WHAT KEY", key, mutationResult)
      key && queryClient.setQueryData(key, mutationResult);
    },
  });
}

/*  ====== LOGOUT =======
   Logou is not asychronous, so we need to use a mutation
*/
export function useLogout() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const router = useRouter();
  return () => {
    pb.authStore.clear();
    router.invalidate();
    queryClient.clear();
    navigate({ to: "/login" });
    showSuccess("Logout Successful", `You have been logged out.`);
  };
}

// export function isAdmin() {
//   return pb.authStore.isAdmin;
// }

// export function isValid() {
//   return pb.authStore.isValid;
// }
