import { useForm } from "@tanstack/react-form";
import { UserFilesRecord } from "@/lib/pb/types";
import { zodValidator } from "@tanstack/zod-form-adapter";
import { useAddFileMutation } from "@/lib/pb/files";
import { Form } from "@/components/form";
import { FileInput } from "@/lms/files/FileInput";
import { useState } from "react";
import { cn } from "@/lib/utils";
import { useEnrollment, useUser } from "@/lib/store";
import { Button } from "@/components/ui/button";
import { Upload, X } from "lucide-react";

export function FileUploader({
  usage,
  maxUsage,
}: {
  usage?: number;
  maxUsage?: number;
}) {
  const user = useUser();
  const enrollment = useEnrollment();
  const { mutateAsync: add, isPending } = useAddFileMutation(user?.id);

  const [selectedFile, setSelectedFile] = useState(null);
  const form = useForm<UserFilesRecord, any>({
    validatorAdapter: zodValidator(),
    defaultValues: { file: undefined },
    onSubmit: async ({ value, formApi }) => {
      if (!selectedFile || isPending) return;
      const { name, size, type } = selectedFile;
      const data = {
        userId: user?.id,
        enrollmentId: enrollment.id,
        name,
        size,
        file: selectedFile,
        type,
      };
      await add({ data }).catch((e) => console.error(e));
      setSelectedFile(null);
      formApi.reset();
    },
  });

  // useEffect(() => {
  //   form.setFieldValue("file", selectedFile ? selectedFile : null);
  // }, [selectedFile]);

  return (
    <Form form={form}>
      <div className="flex flex-col gap-4 my-auto w-[400px]">
        <FileInput
          form={form}
          name="file"
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          usage={usage}
          maxUsage={maxUsage}
        />
        <div className="flex gap-2 mx-auto">
          <Button
            className={cn(
              "rounded-full",
              selectedFile && !isPending ? "animate-bounce" : "",
            )}
            disabled={!selectedFile || isPending}
          >
            <Upload className="h-4 w-4 me-2" />
            Upload
          </Button>
          <Button
            onClick={() => setSelectedFile(null)}
            variant="secondary"
            className={cn("rounded-full", selectedFile ? "" : "")}
            disabled={!selectedFile}
          >
            <X className="h-4 w-4 me-2" />
            Clear
          </Button>
        </div>
      </div>
    </Form>
  );
}
