import React, { forwardRef } from "react";
import {
  Copy,
  ClipboardPaste,
  Undo,
  Redo,
  ListOrdered,
  ZoomIn,
  ZoomOut,
  FileSearch,
  Scissors,

} from "lucide-react";
//import { toast } from "sonner";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

import {CheatsheetButton} from "@/lms/playground/Cheatsheet";
import { ColorResult, GooglePicker } from "@hello-pangea/color-picker";
import { useState } from "react";

import { editor, Range } from "monaco-editor";
import { atom, useAtom } from "jotai";

export const lineNumbersAtom = atom<editor.LineNumbersType>("on");
export const fontSizeAtom = atom(13);

export const MonacoEditorButtons = forwardRef<editor.IStandaloneCodeEditor, {}>(
  (props, ref) => {
    const editorRef =
      ref as React.MutableRefObject<editor.IStandaloneCodeEditor | null>;
    const [lineNumbers, setLineNumbers] = useAtom(lineNumbersAtom);
    const [fontSize, setFontSize] = useAtom(fontSizeAtom);

    const [hexValue, setHexValue] = useState<string>("#FF0000");

    function handleChange(colorResult: ColorResult) {
      setHexValue(colorResult.hex);
      const result = colorResult.hex.substring(1).toUpperCase();
      navigator.clipboard
        .writeText(result)
        .then(() => {
          // toast.success("Copied to clipboard", {
          //   description: `Hexadecimal code: ${result}`,
          // });
        })
        .catch((err) => {
          console.error("Could not copy text: ", err);
        });
    }

    if (!editorRef) return;

    const increaseFontSize = () => {
      const newFontSize = fontSize + 1;
      setFontSize(newFontSize);
      if (editorRef.current) {
        editorRef.current.updateOptions({ fontSize: newFontSize });
      }
    };

    const decreaseFontSize = () => {
      const newFontSize = fontSize - 1;
      if (newFontSize >= 8) {
        // Minimum font size
        setFontSize(newFontSize);
        if (editorRef.current) {
          editorRef.current.updateOptions({ fontSize: newFontSize });
        }
      }
    };

    const handleToggleLineNumbers = () => {
      if (editorRef.current) {
        const newLineNumbers = lineNumbers === "on" ? "off" : "on";
        editorRef.current.updateOptions({ lineNumbers: newLineNumbers });
        setLineNumbers(newLineNumbers);
      }
    };

    const handleUndo = () => {
      if (editorRef.current) {
        editorRef.current.trigger("keyboard", "undo", null);
      }
    };

    const handleRedo = () => {
      if (editorRef.current) {
        editorRef.current.trigger("keyboard", "redo", null);
      }
    };
    const handleToggleComment = () => {
      if (editorRef.current) {
        editorRef.current.trigger(
          "keyboard",
          "editor.action.commentLine",
          null,
        );
      }
    };

    const handleCustomCut = () => {
      if (editorRef.current) {
        const editor = editorRef.current;
        const model = editor.getModel();
        if (!model) return;
        const selection = editor.getSelection();
        if (!selection) return;
        const selectedText = model.getValueInRange(selection);

        // Copy to clipboard
        navigator.clipboard
          .writeText(selectedText)
          .then(() => {
            // Delete selected text
            editor.executeEdits("", [
              {
                range: selection,
                text: "",
                forceMoveMarkers: true,
              },
            ]);

            // Move the cursor to the start of the selection
            editor.setSelection(
              new Range(
                selection.startLineNumber,
                selection.startColumn,
                selection.startLineNumber,
                selection.startColumn,
              ),
            );
          })
          .catch((err) => {
            console.error("Could not copy text: ", err);
          });
      }
    };

    const handleCopy = () => {
      if (editorRef.current) {
        const model = editorRef.current.getModel();
        if (!model) return;
        const selection = editorRef.current.getSelection();
        if (!selection) return;
        const selectedText = model.getValueInRange(selection);
        navigator.clipboard.writeText(selectedText).catch((err) => {
          console.error("Could not copy text: ", err);
        });
      }
    };

    const handlePaste = () => {
      if (editorRef.current) {
        navigator.clipboard
          .readText()
          .then((clipText) => {
            if (!editorRef.current) return;
            const selection = editorRef.current.getSelection();
            if (!selection) return;
            editorRef.current.executeEdits("", [
              {
                range: selection,
                text: clipText,
                forceMoveMarkers: true,
              },
            ]);
          })
          .catch((err) => {
            console.error("Could not read clipboard: ", err);
          });
      }
    };

    const handleAutoFormat = () => {
      if (editorRef.current) {
        editorRef.current.getAction("editor.action.formatDocument")?.run();
      }
    };

    const handleFind = () => {
      if (editorRef.current) {
        editorRef.current.trigger("keyboard", "actions.find", null);
      }
    };

    // const [decorations, setDecorations] = useState([]);

    // const highlightMatches = (searchString) => {
    //   if (editorRef.current && searchString) {
    //     const model = editorRef.current.getModel();
    //     const matches = model.findMatches(
    //       searchString,
    //       true, // Search in all matches case-insensitively
    //       true, // Match case
    //       false, // Use regular expressions
    //       null,
    //       true, // Limit the result count (true for no limit)
    //     );

    //     const newDecorations = matches.map((match) => {
    //       return {
    //         range: match.range,
    //         options: {
    //           inlineClassName: "findMatchInline",
    //         },
    //       };
    //     });

    //     // Apply decorations and keep track of the IDs
    //     const newDecorationIds = editorRef.current.deltaDecorations(
    //       decorations,
    //       newDecorations,
    //     );
    //     setDecorations(newDecorationIds);
    //   }
    // };

    return (
      <div>
        <div className="flex my-1">
          <EditorButton onClick={handleAutoFormat} label="✨">
            Auto Format
          </EditorButton>
          <EditorButton onClick={handleCustomCut} icon={Scissors}>
            Cut
          </EditorButton>
          <EditorButton onClick={handleCopy} icon={Copy}>
            Copy
          </EditorButton>
          <EditorButton onClick={handlePaste} icon={ClipboardPaste}>
            Paste
          </EditorButton>
          <EditorButton onClick={handleUndo} icon={Undo}>
            Undo
          </EditorButton>
          <EditorButton onClick={handleRedo} icon={Redo}>
            Redo
          </EditorButton>
          <EditorButton onClick={handleToggleComment}>//</EditorButton>
          <EditorButton onClick={decreaseFontSize} icon={ZoomOut}>
            Decrease Font Size
          </EditorButton>
          <EditorButton onClick={increaseFontSize} icon={ZoomIn}>
            Increase Font Size
          </EditorButton>
          <EditorButton onClick={handleToggleLineNumbers} icon={ListOrdered}>
            Toggle Line Numbers
          </EditorButton>
          <EditorButton onClick={handleFind} icon={FileSearch}>
            Find
          </EditorButton>
          <div className="sp-c-bxeRRt sp-button px-1.5 h-3 me-auto">
            <DropdownMenu>
              <DropdownMenuTrigger>
                <div className="flex gap-2 h-3">
                  <div
                    className="w-4 h-4"
                    style={{ backgroundColor: hexValue }}
                  ></div>
                  <div className="text-foreground">
                    0x{hexValue.substring(1).toUpperCase()}
                  </div>
                </div>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <GooglePicker
                  className="text-black"
                  color={hexValue}
                  onChange={handleChange}
                />
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
          <CheatsheetButton className={"m-0 me-1 sp-c-bxeRRt  h-5 w-5 "} />
          <CloudFiles />
        </div>
      </div>
    );
  },
);



interface EditorButtonProps {
  icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  children?: React.ReactNode;
  label?: string;
}

import { TooltipButton } from "@/components/format/Buttons";
import { CloudFiles } from "../files/CloudFiles";


function EditorButton({
  children,
  icon: Icon,
  onClick,
  label,
  ...props
}: EditorButtonProps) {
  return (
    <TooltipButton
      variant="secondary"
      className="sp-c-bxeRRt sp-button px-1.5 h-3"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      info={children}
      {...props}
    >
      {Icon ? <Icon className="h-4 w-4" /> : label ? label : children}
    </TooltipButton>
  );
}

// function EditorButton1({
//   children,
//   icon: Icon,
//   onClick,
//   ...props
// }: EditorButtonProps) {
//   return (
//     <TooltipProvider>
//       <Tooltip>
//         <TooltipTrigger asChild>
//           <Button
//             variant="secondary"
//             className="sp-c-bxeRRt sp-button px-1.5 h-3"
//             onClick={(e) => {
//               e.preventDefault();
//               onClick(e);
//             }}
//             {...props}
//           >
//             {Icon ? <Icon className="h-4 w-4" /> : children}
//           </Button>
//         </TooltipTrigger>
//         <TooltipContent>
//           <p>{children}</p>
//         </TooltipContent>
//       </Tooltip>
//     </TooltipProvider>
//   );
// }
