import { IconButton } from "@/components/format/Buttons";
import { ElementAddForm } from "./ElementAdminForm";
import { SectionsResponse } from "@/lib/pb/types";
import { Plus } from "lucide-react";
import { Header, TriggerProps } from "@/components/format/Dialog";
import { DialogForm, useDialog } from "@/components/format/Dialog";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { useState } from "react";

function ElementTypesMenu({ triggerProps }: { triggerProps: Function }) {
  return (
    <div className="flex gap-4 flex-wrap">
      <ElementButton
        triggerProps={triggerProps("markdown")}
        label={`Markdown`}
      />
      <ElementButton
        triggerProps={triggerProps("free-response")}
        label={`Free Reponse`}
      />
      <ElementButton
        triggerProps={triggerProps("multiple-choice")}
        label={`Multiple Choice`}
      />
      <ElementButton
        triggerProps={triggerProps("code-playground")}
        label={`Code Playground`}
      />
      <ElementButton
        triggerProps={triggerProps("image")}
        label={`Image`}
      />
      <ElementButton
        triggerProps={triggerProps("youtube")}
        label={`YouTube`}
      />
      <ElementButton
        triggerProps={triggerProps("file-submission")}
        label={`File Submission`}
      />
      <ElementButton
        triggerProps={triggerProps("link")}
        label={`Link`}
      />
      <ElementButton
        triggerProps={triggerProps("feedback")}
        label={`Feedback`}
      />
      <ElementButton
        triggerProps={triggerProps("paste-json")}
        label={`Paste JSON v1`}
      />

    </div>
  );
}

function useTypesDialog() {
  const dialog = useDialog<HTMLButtonElement>(); //for auto-close
  const [type, setType] = useState<string>("markdown");

  const triggerProps = (type: string) => {
    return {
      ...dialog.triggerProps,
      onClick: (e) => {
        setType(type); //helps to re-use same dialog
        dialog.triggerProps.onClick(e);
      },
    };
  };

  return {
    dialog,
    triggerProps,
    type,
  };
}

export function ElementTypesDialog({ section }: { section: SectionsResponse }) {
  const { dialog, triggerProps, type } = useTypesDialog();

  if (!section) return;

  return (
    <Dialog modal={false}>
      <DialogTrigger asChild>
        <Button variant="outline" className="h-8 px-2">
          <div className="flex item-center">
            <Plus className="h-4 w-4" />
          </div>
        </Button>
      </DialogTrigger>
      <DialogContent className="border-2 border-primary/75 max-w-full max-h-screen h-screen ">
        <Header title="Add Element" />
        <ElementTypesMenu triggerProps={triggerProps} />
      </DialogContent>

      <DialogForm
        dialogProps={dialog.dialogProps}
        contents={ElementAddForm}
        fullScreen={true}
        item={{ section, type }}
      />
    </Dialog>
  );
}

function ElementButton({
  label,
  triggerProps,
}: {
  label: string;
  triggerProps: TriggerProps<HTMLButtonElement>;
}) {
  return (
    <IconButton
      variant="default"
      className="w-48 h-24 text-lg"
      triggerProps={triggerProps}
      label={label}
      icon={Plus}
    />
  );
}
