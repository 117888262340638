import {
  useEditEnrollmentMutation,
  useQuery,
  classesAdminListQuery,
  teamsListQuery,
  useDeleteEnrollmentMutation,
} from "@/lib/pb/lms";
import { ContentsProps, Header } from "@/components/format/Dialog";
import { ConfirmButtons, Form, zodValidator } from "@/components/form";
import { useForm } from "@tanstack/react-form";
import { EnrollmentsResponse, UsersResponse } from "@/lib/pb/types";

import { Label } from "@/components/ui/label";

import { ConfirmDeleteForm } from "@/components/form/Form";
import { SelectField, SelectItem } from "@/components/form/Select";

export function EnrollmentEditForm({
  item,
  onComplete,
}: ContentsProps<EnrollmentsResponse>) {
  if (!item) return;

  const user = item?.expand?.userId;

  const { mutateAsync: edit, isPending } = useEditEnrollmentMutation(item.id);

  const form = useForm({
    validatorAdapter: zodValidator(),
    defaultValues: {
      classId: item.classId,
      teamId: item.teamId,
    },
    onSubmit: async ({ value }) => {
      if (isPending) return;
      await edit({ id: item.id, data: value }).catch((e) => console.log(e));
      onComplete();
    },
  });

  const classId = form.useStore((state) => state.values.classId);

  const { data: classes, ...classesQuery } = useQuery(
    classesAdminListQuery(item.courseId),
  );
  const { data: teams, ...teamsQuery } = useQuery(teamsListQuery(classId));

  return (
    <div className="grid gap-6">
      <Header
        title="Edit User (Admin)"
        description="Make changes to the user here. Click save when you're done."
      />

      <Form form={form} className="grid gap-4">
        <div className="grid grid-cols-2 gap-4 w-full">
          <div className="grid items-center gap-2 ">
            <Label>Name</Label>
            <Label className="ps-3 py-3 border rounded-md text-muted-foreground">
              {user?.name}
            </Label>
          </div>
          <div className="grid items-center gap-2 ">
            <Label>Email</Label>
            <Label className="ps-3 py-3 border rounded-md text-muted-foreground">
              {user?.email}
            </Label>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 w-full">
          <SelectField
            form={form}
            name="classId"
            label="Class"
            className="grid gap-2"
          >
            {classes &&
              classes.map((classItem) => (
                <SelectItem
                  key={classItem.id}
                  className={classItem.active ? "" : "opacity-25"}
                  value={classItem.id}
                >
                  {classItem.name}
                </SelectItem>
              ))}
          </SelectField>

          <SelectField
            form={form}
            name="teamId"
            label="Team"
            className="grid gap-2"
          >
            <SelectItem key="unassigned">Unassigned</SelectItem>
            {teams &&
              teams.map((team) => (
                <SelectItem key={team.id} value={team.id}>{team.name}</SelectItem>
              ))}
          </SelectField>
        </div>

        <div className="flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2">
          <ConfirmButtons
            form={form}
            onComplete={onComplete}
            label="Save changes"
          />
        </div>
      </Form>
    </div>
  );
}

export function EnrollmentDeleteForm({
  item,
  onComplete,
}: ContentsProps<UsersResponse>) {
  if (!item) return <div>No class found.</div>;

  const { mutateAsync: deleteEnrollment, isPending } =
    useDeleteEnrollmentMutation(item.userId);

  const user = item?.expand?.userId;

  const options = {
    validatorAdapter: zodValidator(),
    defaultValues: { email: "" },
    onSubmit: async ({ value }) => {
      if (isPending) return;
      if (value.email !== user?.email) return; //test field value
      await deleteEnrollment({ id: item.id }).catch((e) => console.log(e));
      onComplete();
    },
  };

  return (
    <ConfirmDeleteForm
      formOptions={options}
      info={`This will remove ${user?.name} from "${item.expand.classId.name}" and delete all user progress and submissions.`}
      match={user?.email}
      onComplete={onComplete}
      type="Enrollment"
      fieldName="email"
    />
  );
}

// <div className="text-center">
//   This class has {item.totalEnrollments as number} enrolled and{" "}
//   {item.totalTeams as number} team(s).
// </div>
