import { queryOptions, useQuery } from "@tanstack/react-query";
import {
  useAddMutation,
  useDeleteMutation,
  useEditMutation,
} from "./mutations";
import {
  RecordIdString,
  SubmissionHistoryRecord,
  SubmissionHistoryResponse,
} from "./types";
import { getFullList, getFirst, getGroupList, getFullMap } from ".";
import { DEFAULT_STALETIME } from "./lms";
import { useEffect, useState } from "react";

export const useAddSubmissionHistoryMutation = (
  submissionId: string | null | undefined,
) =>
  useAddMutation<SubmissionHistoryRecord, SubmissionHistoryResponse>(
    "submission_history",
    [submissionHistoryQuery(submissionId).queryKey],
  );

export const useEditSubmissionMutation = (
  submissionId: string | null | undefined,
) =>
  useEditMutation<SubmissionHistoryRecord, SubmissionHistoryResponse>(
    "submissions",
    [submissionHistoryQuery(submissionId).queryKey],
  );

export const useDeleteSubmissionMutation = (submissionId: string) =>
  useDeleteMutation("submissions", [
    submissionHistoryQuery(submissionId).queryKey,
  ]);

export const submissionHistoryQuery = (
  submissionId: string | null | undefined,
) =>
  queryOptions({
    queryKey: ["submission_history", "list", submissionId],
    queryFn: () =>
      getFullList<SubmissionHistoryResponse>("submission_history", {
        filter: `submissionId="${submissionId}"`,
        sort: `-created`,
      }),
    staleTime: Infinity,
    refetchOnWindowFocus: false, // prevents user input from clearing
    refetchOnReconnect: false,
    refetchOnMount: "always",
    retry: false,
    meta: { errorMessage: "Failed to fetch submission history " },
    enabled: !!submissionId,
  });
