import { TooltipSheetButton } from "@/components/format/Buttons";
import { MessageSquare, CornerDownRight, RotateCw } from "lucide-react";
import { ROLES } from "@/lib/roles";
import { Header } from "@/components/format/Dialog";
import { ScrollArea } from "@/components/ui/scroll-area";
import { useUser, useCourse, useClassFilter } from "@/lib/store";
import { ClassFilter } from "../classes/ClassFilter";

import CodeHike from "@/components/codehike/CodeHike";
import { format, formatRelative } from "date-fns";
import { useQuery } from "@/lib/pb/lms";
import { recentCommentsListQuery } from "@/lib/pb/comment";
import { BasicMarkdown } from "@/components/format/BasicMarkdown";
import { TableComments } from "./Comments";
import { Button } from "@/components/ui/button";
import { Link } from "@/components/nav/Link";

export function CommentsButton({ className }: { className?: string }) {
  const user = useUser();
  const isCoach = user?.role >= ROLES.coach;
  // const isAdmin = user?.role == ROLES.administrator;
  const course = useCourse();
  const classFilter = useClassFilter(); //TODO
  const { data: comments, refetch } = useQuery(
    recentCommentsListQuery(course.id, 20),
  );

  if (!user) return;
  if (!isCoach) return;
  return (
    <TooltipSheetButton
      icon={MessageSquare}
      info="Comments"
      variant="ghost"
      className={className}
      showInfo={true}
    >
      <div className="flex flex-col w-full h-screen gap-6">
        <Header
          title={<HeaderComments isCoach={isCoach} refetch={refetch} />}
          description={" "}
        />
        <ScrollArea className="border-t-2">
          <div className="h-[92dvh] flex flex-col w-full gap-2 pe-4">
            {comments &&
              comments.map((comment) => {
                const section = course?.elements[comment.elementId]?.parent;
                return (
                  <div
                    key={comment.id}
                    className="flex gap-4 border-2 p-4 rounded-md"
                  >
                    <div className="flex flex-col w-40">
                      <div>{comment.expand.senderId.name}</div>
                      {comment.senderId != comment.receiverId && (
                        <div className="flex ms-4 text-sm text-muted-foreground">
                          <CornerDownRight className="h-5 w-5" />
                          {comment.expand.receiverId.name}
                        </div>
                      )}
                      {section && <Link className="text-sm mt-2 text-primary" to={section.url}>
                        {section.index} {section.name} 
                      </Link>}
                    </div>
                    {/* <div className="flex w-36">{comment.receiverId}</div> */}
                    <div className="flex w-[450px] bg-accent rounded-md py-1 px-2 line-clamp-2">
                      <BasicMarkdown
                        className="w-[430px] line-clamp-2"
                        code={comment.content}
                      />
                    </div>
                    <div className="flex flex-col w-40 gap-2">
                      <div>
                        <TableComments
                          userId={comment.receiverId}
                          elementId={comment.elementId}
                          classId={comment.classId}
                        />
                      </div>
                      <div className="flex text-xs text-muted-foreground ms">
                        {formatRelative(comment.created, new Date())}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </ScrollArea>
      </div>
    </TooltipSheetButton>
  );
}

function HeaderComments({
  isCoach,
  refetch,
}: {
  isCoach: boolean;
  refetch: () => void;
}) {
  return (
    <div className="flex gap-6 items-center">
      <div className="flex gap-2 items-center text-info">
        <MessageSquare /> <div>Recent Comments</div>
      </div>
      {isCoach && (
        <div className="flex gap-4 items-center text-sm font-normal">
          <ClassFilter />
        </div>
      )}
      {refetch && (
        <Button
          variant="outline"
          className="p-2 h-8 gap-2 items-center"
          onClick={refetch}
        >
          <RotateCw className="h-4" /> Refresh
        </Button>
      )}
    </div>
  );
}

function TeamMarkdown({ team }: { team: TeamsResponse }) {
  if (!team) return;
  return (
    <div className="grid gap-4 mx-4 mt-4">
      <div className="text-3xl font-bold tracking-tight">{team.name}</div>
      <hr />
      <CodeHike className="mt-4" code={team.markdown} />
    </div>
  );
}
