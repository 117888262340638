import {
  Accordion as Acc,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";

export function Accordion({
  title,
  type = "accent",
  children,
  className = "",
  defaultOpen = true,
}) {
  return (
    <Acc
      type="single"
      className={`accordion mb-4 p-0 `}
      collapsible
      defaultValue={defaultOpen ? "item-1" : ""}
    >
      <AccordionItem
        value="item-1"
        className={`bg-${type} rounded-lg border ` + className}
      >
        <AccordionTrigger className={`p-3 rounded-xl text-${type}-foreground `}>
          {title}
        </AccordionTrigger>
        <AccordionContent className="m-1 pt-4 px-4 bg-background rounded-sm">
          {children}
        </AccordionContent>
      </AccordionItem>
    </Acc>
  );
}