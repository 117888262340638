import {
  Input,
  Field,
  Form,
  SubmitButton,
  zodValidator,
} from "@/components/form";
import { Link, useRouter } from "@tanstack/react-router";
import { Label } from "@/components/ui/label";
import { GoogleLogInButton } from "@/components/auth/GoogleLoginButton";
import { useLoginMutation } from "@/lib/pb/auth";
import { useForm, FormApi } from "@tanstack/react-form";
import { EmailField } from "@/components/form/CustomFields";
import { useStaticTranslations } from "@/lib/lang";

export default function LoginForm() {
  const login = useLoginMutation();
  
  const router = useRouter();
  const st = useStaticTranslations();

  const form = useForm({
    validatorAdapter: zodValidator(),
    defaultValues: {
      email: "",
      password: "",
    },
    onSubmit: async ({ value }) => {
      if (login.isPending) return;
      const res = await login.mutateAsync(value).catch((e) => console.log(e));
      router.invalidate();
    },
  });
  return (
    <Form form={form}>
      <div className="grid gap-4">
        <EmailField form={form} className="grid gap-2" />

        <Field
          form={form}
          className="grid gap-2"
          name="password"
          label={
            <div className="flex items-center w-full"> 
              <Label htmlFor="password">{st("Password")}</Label>
              {/* <Link href="#" className="ms-auto inline-block text-sm underline">
                Forgot your password? (todo)
              </Link> */}
            </div>
          }
          children={(field) => <Input type="password" field={field} />}
        />
        <SubmitButton
          className="w-full"
          label={st("Login")}
          form={form}
          disabled={login.isPending}
        />

        <GoogleLogInButton />
      </div>
      <div className="mt-4 text-center text-sm">
        Don't have an account?{" "}
        <Link to="/signup" className="underline">
          {st("Sign up")}
        </Link>
      </div>
    </Form>
  );
}
