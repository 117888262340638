import { Button } from "@/components/ui/button";
import { useAuthWithProvider } from "@/lib/pb/auth";
import { useDarkMode } from "@/lib/store";
import { useRouter } from "@tanstack/react-router";
export function GoogleLogInButton({ isSignUp = false }: { isSignUp?: boolean }) {
  const loginWithProvider = useAuthWithProvider();
  const isDark = useDarkMode();
  const router = useRouter();

  return (
    <Button
      type="button"
      variant="outline"
      onClick={async () => {
        if (loginWithProvider.isPending) return;
        await loginWithProvider.mutateAsync({});
        router.invalidate();
      }}
      className="w-full"
      disabled={loginWithProvider.isPending}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        fill={isDark ? "#fff" : "#000"}
        height="20px"
        width="20px"
        version="1.1"
        id="Capa_1"
        viewBox="0 0 210 210"
        xmlSpace="preserve"
      >
        <path d="M0,105C0,47.103,47.103,0,105,0c23.383,0,45.515,7.523,64.004,21.756l-24.4,31.696C133.172,44.652,119.477,40,105,40  c-35.841,0-65,29.159-65,65s29.159,65,65,65c28.867,0,53.398-18.913,61.852-45H105V85h105v20c0,57.897-47.103,105-105,105  S0,162.897,0,105z" />
      </svg>
      <span className="ps-4 text-foreground">{isSignUp ? "Sign up" : "Login"} with Google</span>
    </Button>
  );
}
