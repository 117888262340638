import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./github-markdown.css";

import {
  QueryClient,
  QueryClientProvider,
  QueryCache,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { RouterProvider, createRouter } from "@tanstack/react-router";
import { toast } from "sonner";
// Import the generated route tree
import { routeTree } from "./routeTree.gen";

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error, query) => {
      console.error(error);
      if (query?.meta && query.meta.errorMessage) {
        if(query.meta.errorMessage.includes("autocancelled")) return
        // toast.error("Error", {
        //   description: `QueryClientError: ${query.meta.errorMessage}`,
        // });
      }
      else if (error && error.message) {
  
        // toast.error("Error", {
        //   description: `QueryClientError:  ${error.message}`,
        // });
      }
    },
  }),
});
// Create a new router instance
const router = createRouter({
  routeTree,
  context: { queryClient },
  defaultPreloadStaleTime: 0,
});

// Register the router instance for type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      <ReactQueryDevtools initialIsOpen={false} buttonPosition="top-left"/>
      
    </QueryClientProvider>
  </React.StrictMode>,
);
