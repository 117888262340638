import { useRef, useState, useCallback } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { ReactNode } from "react";
import { cn } from "@/lib/utils";

export interface DialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export interface TriggerProps<T extends HTMLElement = HTMLElement> {
  ref: React.RefObject<T>;
  onClick: (event: React.MouseEvent<HTMLElement>, ...args: any[]) => any;
}

export function useDialog<T extends HTMLElement>(
  preventDefault = false,
): {
  triggerProps: TriggerProps<T>;
  dialogProps: DialogProps;
} {
  const [isOpen, setIsOpen] = useState(false);
  const triggerRef = useRef<T>(null);

  const trigger = useCallback((e) => {
    if (preventDefault) e.preventDefault();
    setIsOpen(true);
  }, []);

  const dismiss = useCallback(() => {
    setIsOpen(false);
    //triggerRef.current?.focus();
  }, []);

  return {
    triggerProps: {
      ref: triggerRef,
      onClick: trigger,
    },
    dialogProps: {
      open: isOpen,
      onOpenChange: (open: boolean) => (open ? trigger() : dismiss()),
    },
    //   trigger,
    //   dismiss,
  };
}

// interface DialogFormatProps extends DialogProps {
//   children: ReactNode;
//   className?: string;
//   title?: string | ReactNode;
//   description?: string | ReactNode;
// }

// export interface DialogItemProps<TRecord> extends DialogProps {
//   item: TRecord;
// }

export type ContentsProps<TResponse> = {
  item: TResponse;
  onComplete: () => void;
  actionState?: [boolean, Function];
};

type DialogFormProps<TResponse> = {
  item?: TResponse;
  contents: React.ComponentType<ContentsProps<TResponse>>;
  fullScreen?: boolean;
  onComplete?: () => void;
  actionState?: [boolean, Function];
  dialogProps: DialogProps;
  className?: string;
};

export function DialogForm<TResponse>({
  item,
  contents: Contents,
  fullScreen,
  onComplete,
  actionState,
  dialogProps,
  className,
}: DialogFormProps<TResponse>) {
  const { open, onOpenChange } = dialogProps;

  const onCompleteCallback = useCallback(() => {
    if (onComplete) onComplete();
    onOpenChange(false);
  }, [onComplete, onOpenChange]);

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent
        className={cn(
          "bg-slate-100 dark:bg-slate-900",
          fullScreen ? "max-w-full max-h-screen h-screen " : "max-w-3xl",
          className,
        )}
      >
        {Contents && (
          <Contents
            actionState={actionState}
            item={item}
            onComplete={onCompleteCallback}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}

interface HeaderProps {
  title?: string | ReactNode;
  description?: string | ReactNode;
  className?: string;
}

export function Header({ title, description, className }: HeaderProps) {
  return (
    <DialogHeader className={className}>
      {title ? <DialogTitle>{title}</DialogTitle> : null}

      <DialogDescription>{description ? description : ""}</DialogDescription>
    </DialogHeader>
  );
}
