import { useMemo } from "react";
import { Table, PaginationTable, FilterTable } from "@/components/table";
import { useQuery } from "@tanstack/react-query";
import { teamsListQuery } from "@/lib/pb/lms";
import { ColumnDef } from "@tanstack/react-table";
import { TeamsViewResponse } from "@/lib/pb/types";
import { Pencil, Trash2 } from "lucide-react";

export const TEAMS_COLUMNS: ColumnDef<TeamsViewResponse>[] = [
  {
    accessorKey: "name",
    header: () => <span>Name</span>,
  },
  {
    accessorKey: "totalEnrollments",
    header: () => <span>Members</span>,
  },
  {
    id: "action",
    cell: ({ row }) => (
      <div className="flex">
        <DialogButton
          icon={Pencil}
          item={row.original}
          contents={TeamEditForm}
          className="mx-auto"
          fullScreen={true}
          variant="ghost"
        />
        <DialogButton
          icon={Trash2}
          item={row.original}
          contents={TeamDeleteForm}
          className="mx-auto"
          variant="ghost"
        />
      </div>
    ),
  },
];

export function TeamsTable({ classId }: { classId: string }) {
  const { isPending, error, data: teams } = useQuery(teamsListQuery(classId));
  const columns = useMemo(() => TEAMS_COLUMNS, []);

  if (error) return "An error has occurred: " + error.message;

  if (teams && teams.length == 0) {
    return <div className={"w-full text-md text-center "}>No teams found</div>;
  }
  return (
    <div className={"w-full m-auto grid gap-4"}>
      <Table
        data={teams}
        columns={columns}
        sortEnabled={true}
        isPending={isPending}
      />
    </div>
  );
}

import { useForm } from "@tanstack/react-form";
import { ConfirmButtons } from "@/components/form";
import { MarkdownField, NameField } from "@/lms/core/LmsFields";
import { useDeleteTeamMutation, useEditTeamMutation } from "@/lib/pb/lms";
import { Form, zodValidator } from "@/components/form";
import { DialogButton } from "@/components/format/Buttons";

import { ContentsProps, Header } from "@/components/format/Dialog";
import { useTeamFilter, setTeamFilter, teamFilterState } from "@/lib/store";

export function TeamDeleteForm({
  item,
  onComplete,
}: ContentsProps<TeamsViewResponse>) {
  if (!item) return;
  const teamFilter = useTeamFilter();
  
  const { mutateAsync: deleteTeam, isPending } = useDeleteTeamMutation(
    item.courseId,
    item.classId,
  );

  return (
    <div>
      <Header
        title="Confirm Delete Team"
        description="This action is permanent and cannot be undone. Are you sure you want to proceed?"
      />

      <div className="p-6">
        <ConfirmButtons
          variant="destructive"
          onComplete={onComplete}
          onClick={async () => {
            if (isPending) return;
            const res = await deleteTeam({ id: item.id }).catch((e) => console.error(e));

            if(res) {
              //if we deleted the current team, reset the filter
              if(item.id == teamFilter.id) { 
                setTeamFilter(null)
              }
            }
            onComplete();
          }}
          label="Delete Team"
        />
      </div>
    </div>
  );
}

export function TeamEditForm({
  item,
  onComplete,
}: ContentsProps<TeamsViewResponse>) {
  if (!item) return;
  const { mutateAsync: edit, isPending } = useEditTeamMutation(
    item.id,
    item.classId,
  );

  const form = useForm({
    validatorAdapter: zodValidator(),
    defaultValues: { name: item.name ?? "", markdown: item.markdown ?? "" },
    onSubmit: async ({ value }) => {
      if (isPending) return;
      await edit({ id: item.id, data: value }).catch((e) => console.log(e));
      onComplete();
    },
  });
  return (
    <div>
      <Header
        title="Edit Team"
        description="Make changes to your team here. Click save when you're done."
      />

      <Form form={form}>
        <div className="grid gap-4 py-4">
          <NameField form={form} className="grid items-center gap-2 max-w-md" />
          <MarkdownField form={form} name="markdown" label="Markdown" />
        </div>
        <div className="flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2">
          <ConfirmButtons
            form={form}
            onComplete={onComplete}
            label="Save changes"
          />
        </div>
      </Form>
    </div>
  );
}
