import { FullDialogButton, TooltipSheetButton } from "@/components/format/Buttons";
import { CircleHelp, Pencil } from "lucide-react";
import CodeHike from "@/components/codehike/CodeHike";
import { Header } from "@/components/format/Dialog";
import { ScrollArea } from "@/components/ui/scroll-area";
import { useCourse, useUser } from "@/lib/store";
import { ROLES } from "@/lib/roles";
import { CourseEditForm } from "../course/CourseAdminForms";

export function CheatsheetButton({
  className,
  showInfo,
}: {
  className?: string;
  showInfo?: boolean;
}) {
  const course = useCourse();
  const user = useUser();

  const isAdmin = user?.role == ROLES.administrator;

  if (!course.cheatsheet && !isAdmin) return;
  return (
    <TooltipSheetButton
      icon={CircleHelp}
      info="Cheatsheet"
      variant="ghost"
      size="sm"
      className={className}
      showInfo={showInfo}
    >
      <div className="flex flex-col w-full h-screen gap-6">
        <Header
          title={<HeaderCheatsheet isAdmin={isAdmin} />}
          description={""}
        />
        <ScrollArea className="">
          <div className="h-[92dvh] flex flex-col w-full gap-6">
            <Cheatsheet />
          </div>
        </ScrollArea>
      </div>
    </TooltipSheetButton>
  );
}

function HeaderCheatsheet({ isAdmin }: { isAdmin: boolean }) {
  const course = useCourse();
  return (
    <div className="flex gap-2 items-center text-success">
      <CircleHelp /> Course Cheatsheet
      {isAdmin && (
        <div className="text-foreground">
          <FullDialogButton
            icon={Pencil}
            item={course.original || course}
            contents={CourseEditForm}
            variant="outline"
            className="h-6 px-2 w-6 "
          />
        </div>
      )}
    </div>
  );
}

export function Cheatsheet() {
  const course = useCourse();

  if (!course) return null;
  return <CodeHike code={course.cheatsheet} />;
}
