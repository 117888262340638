import { Field } from "@/components/form";
import {
  GenericInputField,
  FieldProps,
  GenericTextareaField,
} from "@/components/form/CustomFields";
import { MDEditor } from "@/components/form/MDEditor";
import { Label } from "@/components/ui/label";
import { requiredSchema } from "@/lib/validation";
import { FormApi } from "@tanstack/react-form";
import { z } from "zod";
import { SwitchField } from "@/components/form/CustomFields";
import { SelectField, SelectItem } from "@/components/form/Select";
export interface LmsFieldProps extends FieldProps {
  name: string;
  label?: string;
  info?: string;
  disabled?: boolean;
  description?: React.ReactNode;
  rows?: string;
  onChange?: (value: any) => void;
  //type: string;
  //schema: any;
}

export const NameField = (props: FieldProps) => (
  <GenericInputField
    {...props}
    name="name"
    label="Name"
    schema={requiredSchema}
    type="text"
  />
);

export const RequiredTextField = (props: LmsFieldProps) => (
  <GenericInputField {...props} schema={requiredSchema} type="text" />
);

export const OptionalTextField = (props: LmsFieldProps) => (
  <GenericInputField {...props} type="text" />
);

export const OptionalTextareaField = (props: LmsFieldProps) => (
  <GenericTextareaField {...props} />
);

export const RequiredDateField = (props: LmsFieldProps) => (
  <GenericInputField {...props} schema={requiredSchema} type="date" />
);

export const OptionalDateField = (props: LmsFieldProps) => (
  <GenericInputField {...props} type="date" />
);

export const MatchTextField = (props: LmsFieldProps) => (
  <GenericInputField
    {...props}
    schema={z
      .string()
      .regex(
        new RegExp(`^${props.info.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")}$`),
        {
          message: "The field does not match.",
        },
      )}
    type="text"
  />
);

export const MarkdownField = ({
  label,
  name,
  form,
  className,
  disabled,
  vertical,
  size,
}: LmsFieldProps & {
  vertical?: boolean | undefined;
  size?: string | undefined;
}) => {
  return (
    <>
      <Label>{label}</Label>
      <Field
        form={form}
        className={className}
        name={name}
        validator={null}
        children={(field) => (
          <MDEditor
            id={field?.name}
            name={field?.name}
            value={field?.state.value}
            onChange={(newValue) => field?.handleChange(newValue)}
            disabled={disabled}
            vertical={vertical}
            size={size}
          />
        )}
      />
    </>
  );
};

export const UrlField = (props: LmsFieldProps) => (
  <GenericInputField
    schema={z
      .string()
      .url({ message: "Invalid URL" })
      .optional()
      .or(z.literal(""))}
    type="url"
    {...props}
  />
);

export const ActiveSwitchField = ({
  disabled = false,
  form,
}: {
  disabled?: boolean;
  form: FormApi<any, any>;
}) => (
  <SwitchField
    form={form}
    className="grid border-s-2 ps-2"
    name="active"
    label="Active"
    disabled={disabled}
  />
);

export const SubmissionRequiredSwitchField = ({
  disabled = false,
  form,
}: {
  disabled?: boolean;
  form: FormApi<any, any>;
}) => (
  <SwitchField
    form={form}
    className="grid border-s-2 ps-2"
    name="submissionRequired"
    label="Submission Required"
    disabled={disabled}
  />
);

export const EnableCommentsSwitchField = ({
  disabled = false,
  form,
}: {
  disabled?: boolean;
  form: FormApi<any, any>;
}) => (
  <SwitchField
    form={form}
    className="grid border-s-2 ps-2"
    name="enableComments"
    label="Enable Comments"
    disabled={disabled}
  />
);

export const EnableHistorySwitchField = ({
  disabled = false,
  form,
}: {
  disabled?: boolean;
  form: FormApi<any, any>;
}) => (
  <SwitchField
    form={form}
    className="grid border-s-2 ps-2"
    name="enableHistory"
    label="Enable History"
    disabled={disabled}
  />
);

export const EnableTeamViewSwitchField = ({
  disabled = false,
  form,
}: {
  disabled?: boolean;
  form: FormApi<any, any>;
}) => (
  <SwitchField
    form={form}
    className="grid border-s-2 ps-2"
    name="enableTeamView"
    label="Enable Team View"
    disabled={disabled}
  />
);

import { ROLES, RoleNames } from "@/lib/roles";
import { cn } from "@/lib/utils";
import { ReactNode } from "react";

type AccessFieldProps = {
  label?: string;
  className: string;
  form: FormApi<any, any>;
};

export const AccessField = ({
  className,
  form,
  label = "Access",
}: AccessFieldProps) => {
  return (
    <SelectField
      form={form}
      name="access"
      label={label}
      className={cn("w-48 ", className)}
      type="number"
    >
      {ROLES &&
        Object.entries(RoleNames)
          ?.reverse()
          .map((role) => {
            if (role[0] == "0") return; //remove public access
            return (
              <SelectItem key={role[0]} value={role[0]} className="flex ">
                <span className="pe-4">{role[0]}</span>
                <span>{role[1]}+</span>
              </SelectItem>
            );
          })}
    </SelectField>
  );
};

export const PublicAccessField = ({
  className,
  form,
  label = "Access",
}: AccessFieldProps) => {
  return (
    <SelectField
      form={form}
      name="access"
      label={label}
      className={"w-48 " + className}
      type="number"
    >
      {ROLES &&
        Object.entries(RoleNames)
          ?.reverse()
          .map((role) => {
            return (
              <SelectItem key={role[0]} value={role[0]} className="flex ">
                <span className={role[0] == "0" ? "pe-8" : "pe-4"}>
                  {role[0]}
                </span>
                <span>{role[1]}+</span>
              </SelectItem>
            );
          })}
    </SelectField>
  );
};

export const RoleField = ({
  className,
  form,
  label = "Role",
}: AccessFieldProps) => {
  return (
    <SelectField
      form={form}
      name="role"
      label={label}
      className={"w-48 " + className}
      type="number"
    >
      {ROLES &&
        Object.entries(RoleNames)
          ?.reverse()
          .map((role) => {
            if (role[0] == "0") return; //remove public access
            return (
              <SelectItem key={role[0]} value={role[0]} className="flex ">
                <span className="pe-4">{role[0]}</span>
                <span>{role[1]}+</span>
              </SelectItem>
            );
          })}
    </SelectField>
  );
};

const placements = ["top", "bottom", "left", "right"];

export const PlacementSelectField = ({
  className,
  form,
}: {
  form: FormApi<any, any>;
  className?: string;
}) => {
  return (
    <SelectField
      form={form}
      name="placement"
      label="Text Placement"
      className={"w-32 " + className}
    >
      {placements.map((placement) => {
        return (
          <SelectItem key={placement} value={placement} className="flex ">
            <span className="pe-4">{placement}</span>
          </SelectItem>
        );
      })}
    </SelectField>
  );
};

import { Widths, Heights } from "@/lib/sizes";

export function WidthSelectField({
  form,
  label = "Width",
  className,
}: {
  form: FormApi<any, any>;
  label?: ReactNode;
  className?: string;
}) {
  return (
    <SelectField form={form} name="width" label={label} className={className}>
      {Object.keys(Widths).map((h) => (
        <SelectItem key={h} value={h}>
          {h}
        </SelectItem>
      ))}
    </SelectField>
  );
}

export function HeightSelectField({
  form,
  label = "Height",
  className,
}: {
  form: FormApi<any, any>;
  label?: ReactNode;
  className?: string;
}) {
  return (
    <SelectField form={form} name="height" label={label} className={className}>
      {Object.keys(Heights).map((h) => (
        <SelectItem key={h} value={h}>
          {h}
        </SelectItem>
      ))}
    </SelectField>
  );
}

export function CommonAdminFields({
  form,
  access = "200",
  children,
}: {
  form: FormApi<any, any>;
  access?: string;
  children?: React.ReactNode;
}) {
  return (
    <div className="flex flex-wrap gap-4 xl:gap-6 w-full my-auto mb-16">
      <AccessField
        label={`Access (default: ${access})`}
        form={form}
        className="grid gap-1.5"
      />
      <ActiveSwitchField form={form} />
      {children}
    </div>
  );
}
