//========================
import { compile, run, CompileOptions } from "@mdx-js/mdx";
import { remarkCodeHike, recmaCodeHike } from "codehike/mdx";
import * as runtime from "react/jsx-runtime";
import { useState, useEffect, useCallback } from "react";
import { Code, InlineCode } from "./CHCode";
import Scrollycoding from "./Scrollycoding";
import Spotlight from "./Spotlight";
import Alert from "./Alert";
import rehypeExternalLinks from "rehype-external-links";
import rehypeSlug from "rehype-slug";
import remarkGfm from "remark-gfm";
import remarkBreaks from "remark-breaks";
import rehypeCollectHeadings from "@/lms/core/rehypeCollectHeadings";
import { Skeleton } from "@/components/ui/skeleton";
import { ErrorBoundary } from "react-error-boundary";
import { ElementsResponse } from "@/lib/pb/types";
import rehypeAddCrossorigin from "@/lms/core/rehypeCrossOriginImage";
import { ImageViewer } from "@/lms/elements/Image";
import { Accordion } from "./Accordion";



const chConfig = {
  components: { code: "Code", inlineCode: "InlineCode" },
  // syntaxHighlighting: {theme: "light-plus"},
};

export const mdxOptions: CompileOptions = {
  outputFormat: "function-body",
  remarkPlugins: [remarkGfm, remarkBreaks, [remarkCodeHike, chConfig]],
  recmaPlugins: [[recmaCodeHike, chConfig]],
  rehypePlugins: [
    [rehypeExternalLinks, { target: "_blank" }],
    rehypeSlug,
    rehypeAddCrossorigin,
    rehypeCollectHeadings,
  ],
};

interface CodeHikeProps {
  className?: string;
  item?: any;
  code: string;
}

export default function CodeHike(props: CodeHikeProps) {
  return (
    <ErrorBoundary resetKeys={[props.code]} FallbackComponent={ErrorFallback}>
      <InnerPreview {...props} />
    </ErrorBoundary>
  );
}

function ErrorFallback({ error }: { error: any }) {
  return (
    <div className="preview-error text-destructive">
      <h3>Runtime Error:</h3>
      <pre>{String(error)}</pre>
    </div>
  );
}
export async function compileElements(elements: ElementsResponse[]) {
  if (!elements) return [];
  elements.forEach(async (el) => {
    if (el.type == "markdown" && el.data?.code) {
      el.compiled = await compileCode(el.data.code);
    } else if (el.type == "image" && el.data?.description) {
      el.compiled = await compileCode(el.data.description);
    } else if (el.type == "youtube" && el.data?.description) {
      el.compiled = await compileCode(el.data.description);
    } else if (el.type == "link" && el.data?.description) {
      el.compiled = await compileCode(el.data.description);
    } else if (el.type == "code-playground" && el.data?.instruction) {
      el.compiled = await compileCode(el.data.instruction);
    }
  });
  return elements;
}

async function compileCode(code: string) {
  return await compile(code, mdxOptions);
}

// https://mdxjs.com/guides/mdx-on-demand/
// https://www.sandromaglione.com/articles/how-to-compile-and-run-mdx-in-react
async function compileAndRun({ code, item }: CodeHikeProps) {
  try {
    //use pre-compiled code if available
    const c = item?.compiled ?? (await compileCode(code));
    //const c =  await compileCode(props.code);
    const x = await run(String(c), runtime);
    return { content: x.default, error: null };
  } catch (e: any) {
    return { content: null, error: e.message };
  }
}

interface ContentResultProp {
  content: any;
  error: any;
}

function useInput({ code, item }: CodeHikeProps) {
  const [{ content, error }, setState] = useState<ContentResultProp>({
    content: null,
    error: null,
  });
  const [loading, setLoading] = useState(true);

  const compileAndRunEffect = useCallback(() => {
    //  console.log("compile and run called WHAT??? ");
    setLoading(true);
    compileAndRun({ code, item }).then((result: any) => {
      setState(result);
      setLoading(false);
    });
  }, [code, item]);

  useEffect(() => {
    compileAndRunEffect();
  }, []);

  useEffect(() => {
    //console.log("useInput effect called");
    let timerId;
    timerId = setTimeout(() => {
      //  console.log("debounce time out called and run called");
      compileAndRunEffect();
    }, 1000); // 200ms debounce delay

    return () => {
      clearTimeout(timerId); // Clear the timer on cleanup
    };
  }, [code, item, compileAndRunEffect]);

  return { content, error, loading };
}

const style = `
.markdown-body h1, 
.markdown-body kbd,
.markdown-body mark,
.markdown-body blockquote, 
.markdown-body slot > *, 
.markdown-body > * {
  max-width: 768px;
  margin-left: auto;   /* Centers the element horizontally */
  margin-right: auto;  /* Centers the element horizontally */
}
.markdown-body .wide-content {
  max-width: 1280px;
} 
.markdown-body table {
  width: 768px;
}
.accordion h3 {
  margin: 0;
}`;

function InnerPreview({ className, item, code }: CodeHikeProps) {
  const { content: Content, error, loading } = useInput({ item, code });
  if (!code) return null;
  // if (loading) return <Skeleton className="mx-auto max-w-[840px] h-12" />;
  return (
    <div className={className}>
      {error ? <ErrorMessage error={error} /> : null}
      <style>{style}</style>
      <div className={"markdown-body "}>
        {Content ? (
          <Content
            components={{
              Code,
              InlineCode,
              Scrollycoding,
              Spotlight,
              Alert,
              Accordion,
              ImageViewer,
            }}
          />
        ) : null}
      </div>
    </div>
  );
}


function ErrorMessage({ error }) {
  return (
    <div className="text-destructive">
      <h4>Compliation Error:</h4>
      <pre>{error}</pre>
    </div>
  );
}

// function TableOfContents({ headings }) {
//   return (
//     <nav>
//       <ul>
//         {headings.map((heading) => (
//           <li
//             key={heading.id}
//             style={{ marginLeft: (heading.depth - 1) * 20 }}
//           >
//             <a href={`#${heading.id}`}>{heading.text}</a>
//           </li>
//         ))}
//       </ul>
//     </nav>
//   );
// }
