import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { useLogout } from "@/lib/pb/auth";
import { useUser, useTeam} from "@/lib/store";
import { EditProfileForm } from "@/components/auth/EditProfileForm";
import { DialogForm, useDialog } from "@/components/format/Dialog";
import { Avatar } from "@/components/format/Avatar";
import { RoleNames } from "@/lib/roles";
export default function ProfileMenu() {
  const user = useUser();
  const team = useTeam();
  const { triggerProps, dialogProps } = useDialog<HTMLDivElement>();
  const logout = useLogout();
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="rounded-full px-0">
          <Avatar user={user} />
          <span className="sr-only">Toggle user menu</span>
          <Label className="text-start max-w-48 truncate overflow-hidden text-ellipsis my-auto px-2">
            <div>{user?.name}</div>
            {team && (
              <div className="tracking-tight text-xs">{team.name}</div>
            )}
          </Label>
          
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent align="start">
        <DropdownMenuLabel>{user?.email}</DropdownMenuLabel>
        <DropdownMenuLabel className="text-muted-foreground text-xs">
          {RoleNames[user?.role]}
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem {...triggerProps}>Edit Profile</DropdownMenuItem>
        <DropdownMenuItem>Support</DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={() => logout()}>Logout</DropdownMenuItem>
      </DropdownMenuContent>

      <DialogForm
        dialogProps={dialogProps}
        className="sm:max-w-md"
        contents={EditProfileForm}
      />
    </DropdownMenu>
  );
}
