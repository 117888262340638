import CodeHike from "@/components/codehike/CodeHike";
import { ElementsResponse, SubmissionsResponse } from "@/lib/pb/types";
import { ElementTitle } from "@/lms/element/ElementTitle";
import { Form } from "@/components/form";
import { File, X } from "lucide-react";
import { useSubmit, useSubmissionDetail } from "@/lib/pb/submissions";
import { FileInput } from "@/lms/files/FileInput";
import { useEnrollment, useUser } from "@/lib/store";
import { useForm } from "@tanstack/react-form";
import { useState } from "react";
import { ElementSubmissionNav } from "../element/ElementSubmissonNav";
import { Button } from "@/components/ui/button";
import { cn, formatBytes } from "@/lib/utils";
import { getUrl } from "@/lib/pb";
import { ImageViewer } from "./Image";
import FileMimeIcon from "../files/FileMimeIcon";

export default function FileSubmission({
  element,
  children,
}: {
  element: ElementsResponse;
  children?: React.ReactNode;
}) {
  if (!element) return null;
  const { data } = element;
  if (!data) return null;
  return (
    <div className="flex w-full justify-center">
      <div className="w-full max-w-3xl flex flex-col gap-2 ">
        <ElementTitle icon={File} title={"File Submission"}>
          {children}
        </ElementTitle>
        <div className="flex flex-col gap-3 bg-accent/40 px-4 py-2 rounded-md border border-primary/50">
          <CodeHike item={element} code={data.question} />
          <FileSubmissionForm element={element} data={data} />
        </div>
      </div>
    </div>
  );
}

function FileSubmissionForm({
  element,
  data,
}: {
  element: ElementsResponse;
  data: any;
}) {
  const user = useUser();
  const enrollment = useEnrollment();
  const { data: submission } = useSubmissionDetail(
    element.id,
    element.sectionId,
    enrollment?.id,
    element.submissionRequired,
  );
  const { submit, isPending: submitPending } = useSubmit(
    element.id,
    element.sectionId,
    enrollment?.id,
  );
  const [selectedFile, setSelectedFile] = useState(null);
  const formOptions = {
    defaultValues: { file: undefined },
    onSubmit: async ({ value, formApi }: { value: any; formApi: any }) => {
      if (!selectedFile || submitPending) return;
      const { name, size, type } = selectedFile;
      const data = {
        elementId: element.id,
        enrollmentId: enrollment?.id,
        teamId: enrollment?.teamId,
        name: user?.name,
        attempts: submission ? submission.attempts + 1 : 1,
        data: JSON.stringify({ name, size, type }), //bug if dont convert to json
        file: selectedFile,
        complete: true,
      };

      if (!submit) throw new Error("no submit function");
      const result = await submit(data, submission?.id);
      setSelectedFile(null);
      if (result) formApi.reset();
    },
  };
  const form = useForm(formOptions);

  return (
    <Form form={form}>
      <fieldset>
        <div className="w-full flex flex-col items-center gap-4">
          {!selectedFile && submission && (
            <FilePreview submission={submission} />
          )}
          <div
            className={cn(
              "w-full flex justify-center",
              !selectedFile && submission ? "opacity-50" : "",
            )}
          >
            <FileInput
              form={form}
              name="file"
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
            />
          </div>
        </div>
        <ElementSubmissionNav
          item={element}
          form={form}
          disabled={submitPending || !selectedFile}
          submission={submission}
          hint={data.hint}
          className="mt-3"
        >
          {selectedFile && (
            <Button
              onClick={() => setSelectedFile(null)}
              variant="secondary"
              className={cn("h-9 rounded-full")}
              disabled={!selectedFile}
            >
              <X className="h-4 w-4 me-2" />
              Cancel
            </Button>
          )}
        </ElementSubmissionNav>
      </fieldset>
    </Form>
  );
}

function FilePreview({ submission }: { submission: SubmissionsResponse }) {
  if (!submission) return;
  const { type, name, size } = submission.data;
  const isImage = type.startsWith("image");
  return (
    <div className="flex flex-col items-center gap-1 border-2 p-2 rounded-md">
      <div className="text-xs text-muted-foreground mb-1">Submitted file:</div>
      <div className="max-h-[100px] max-w-[100px]">
        {isImage ? (
          <ImageViewer
            className="bg-white border-2"
            url={getUrl(submission)}
            useThumbnail={true}
          />
        ) : (
          <a href={getUrl(submission)} download={name}>
            <FileMimeIcon
              mimeType={type}
              className="h-[40px] w-[40px] mx-auto"
            />
          </a>
        )}
      </div>
      <div className="text-xs line-clamp-1">
        {name} - {formatBytes(size)}
      </div>
    </div>
  );
}
