//import NavBrand from "./NavBrand";
import { NavLink, links } from "./Link";

import { useUser } from "@/lib/store";

import {
  NavigationMenu,
  NavigationMenuList,
} from "@/components/ui/navigation-menu";

import { Title } from "./NavBox";
export default function NavBarLeft() {
  const user = useUser();
  return (
    <nav className="hidden flex-col gap-4 text-lg font-medium md:flex md:flex-row md:items-center md:text-sm ">
      <Title title="LMS2" url="/" />
      {user && (
        <NavigationMenu>
          <NavigationMenuList>
            {links.map((link) => (
              <NavLink to={link.to} key={link.to}>
                {link.label}
              </NavLink>
            ))}
          </NavigationMenuList>
        </NavigationMenu>
      )}
    </nav>
  );
}
