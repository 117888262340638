import { Outlet, createFileRoute, Link } from "@tanstack/react-router";
import { SearchButton } from "@/components/sidebar/nav-search";
import { BreadcrumbNav } from "@/components/nav/BreadcrumbNav";
import { Footer } from "@/components/nav/Footer";
//import { useMemo } from "react";

import {
  useUser,
  useUnit,
  useTeam,
  setUnit,
  setLesson,
  setSection,
  useCourse,
  useLesson,
} from "@/lib/store";
import { ROLES } from "@/lib/roles";

import UnitAdminMenu from "@/lms/unit/UnitAdminMenu";
import LessonAdminMenu from "@/lms/lesson/LessonAdminMenu";
import SectionAdminMenu from "@/lms/section/SectionAdminMenu";
import { Clipboard } from "@/lms/core/Clipboard";

import { AppSidebar } from "@/components/sidebar/app-sidebar";
import { SidebarLayout, SidebarTrigger } from "@/components/ui/sidebar";
import { useEffect } from "react";

import { FullDialogButton } from "@/components/format/Buttons";
import { CoursesResponse, UnitsResponse } from "@/lib/pb/types";
import { CourseEnrollmentsDialog } from "@/lms/course/CourseEnrollmentsDialog";
import { UnitProgressDialog } from "@/lms/unit/UnitProgressDialog";
import { LessonProgressDialog } from "@/lms/lesson/LessonProgressDialog";
import { enrollmentsListQuery, useQuery } from "@/lib/pb/lms";

export const Route = createFileRoute("/_auth/course/$courseId/$unitId")({
  component: UnitPage,
});

export function UnitPage() {
  const { unitId } = Route.useParams();
  const course = useCourse();
  const unit = useUnit();
  const units = course?.children ?? []; //array
  
  // const team = useTeam();
  // const filter = team ? `teamId="${team.id}"` : `teamId="NO_TEAM"`;
  // const {
  //   data: teamEnrollments,
  //   error,
  //   isPending,
  // } = useQuery(enrollmentsListQuery(filter));
  // console.log("team:", teamEnrollments)
  

  useEffect(() => {
    let newUnit = null;
    if (course?.units) {
      //object
      newUnit = course.units[unitId] ?? null;
      // if (newUnit) {
      //   if (unit && unit.id !== newUnit.id) {
      //     console.log("reset section and lesson");
      //    // setSection(null);
      //    // setLesson(null);
      //   }
      // }
    }
    //console.log("setting unit ", newUnit?.name);
    setUnit(newUnit);
  }, [unitId, course]);

  const user = useUser();
  const isAdmin = user?.role === ROLES.administrator;
  const isInstructor = user?.role >= ROLES.instructor;
  const isCoach = user?.role >= ROLES.coach;
  //max width 840px
  //
  if (!unit)
    return <div className="w-full pt-16 text-center">No unit: {unitId}</div>;

  return (
    <div className="">
      <SidebarLayout defaultOpen={true}>
        {units && (
          <AppSidebar
            units={units}
            details={{
              unit: isAdmin ? UnitAdminMenu : null,
              section: isAdmin ? SectionAdminMenu : null,
              lesson: isAdmin ? LessonAdminMenu : null,
            }}
          />
        )}
        <div className="flex flex-col w-full">
          <div className="fixed xl:ms-1 h-[100dvh]">
            <SidebarTrigger />
          </div>

          <div className="flex flex-col gap-6 w-full">
            <div className="flex w-full gap-4">
              <div
                id="section-top"
                className="flex w-full max-w-7xl pt-0 lg:pt-4 justify-center  mx-auto"
              >
                <div className="w-full max-w-3xl flex my-0 h-8 gap-4 items-center ">
                  {/* <BreadcrumbNav /> */}
                  {/* <SearchButton > */}
                  {isInstructor && course && <AdminMenuBar course={course} />}
                  {isCoach && unit && <CoachMenuBar unit={unit} />}
                  {isInstructor && <Clipboard />}
                </div>
              </div>
              <div className=" flex w-60 shrink-0 max-2xl:hidden xl:w-72 "></div>
            </div>

            <Outlet />
            <div className="flex mx-auto">
              <Footer />
            </div>
          </div>
        </div>
      </SidebarLayout>
    </div>
  );
}

function CoachMenuBar({ unit }: { unit: UnitsResponse }) {
  const lesson = useLesson();
  if (!unit) return null;
  return (
    <div className="flex gap-2">
      <FullDialogButton
        item={unit}
        contents={UnitProgressDialog}
        label={`Unit ${unit.index} Progress`}
        variant="ghost"
      />
      {lesson && <FullDialogButton
        item={unit}
        contents={LessonProgressDialog}
        label={`Lesson ${lesson.index} Progress`}
        variant="ghost"
      />}
    </div>
  );
}
function AdminMenuBar({ course }: { course: CoursesResponse }) {
  return (
    <div className="flex gap-2">
      <FullDialogButton
        item={course}
        contents={CourseEnrollmentsDialog}
        label="Enrollments"
        variant="ghost"
      />
    </div>
  );
}

//adding here because $courseId_ doesnt have the same context
// beforeLoad: async ({
//   context: { queryClient },
//   params: { courseId, unitId },
// }) => {
//   // !!! components that read these values from useRouteContext dont get updated
//   try {
//     const results = await Promise.all([
//       queryClient.ensureQueryData(courseDetailQuery(courseId)),
//       queryClient.ensureQueryData(unitDetailQuery(unitId)),
//     ]);
//     console.log(results);
//     return { course: results[0], unit: results[1] };
//   } catch (e) {
//     console.error("Error with unit route: ", e);
//     return { course: null, unit: null };
//   }
// },

// {false && units && (
//   <Sidebar
//     unit={unit}
//     units={units}
//     details={{
//       unit: isAdmin ? UnitAdminMenu : null,
//       section: isAdmin ? SectionAdminMenu : null,
//       lesson: isAdmin ? LessonAdminMenu : null,
//     }}
//   />
// )}

