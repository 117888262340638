import { ElementsResponse } from "@/lib/pb/types";

import { useEnrollment, useUser } from "@/lib/store";

import { Comments } from "../comments/Comments";

export default function Feedback({
  element,
  children,
}: {
  element: ElementsResponse;
  children?: React.ReactNode;
}) {
  const user = useUser();
  const enrollment = useEnrollment();
  if (!element) return null;

  const { data } = element;
  if (!data) return null;
  if(!enrollment) return null;

  return (
    <div className="flex w-full justify-center">
      <div className="max-w-3xl flex flex-col gap-2 ">
        <div className="ms-auto">
          {children ? children : <div className="h-10"></div>}
        </div>
        <div className="flex flex-col gap-3  px-4 py-2 ">
          <div className="text-muted-foreground">{data.question}</div>
          <div className="flex justify-center">
            <Comments userId={user.id} elementId={element.id} classId={enrollment.classId}/>
          </div>
        </div>
      </div>
    </div>
  );
}
