import CodeHike from "@/components/codehike/CodeHike";
import { ElementsResponse } from "@/lib/pb/types";
import { Form } from "@/components/form";
import { useForm } from "@tanstack/react-form";
import { CircleHelp } from "lucide-react";
import { useSubmit, useSubmissionDetail } from "@/lib/pb/submissions";
import { useEnrollment, useUser } from "@/lib/store";
import { BasicMarkdown } from "@/components/format/BasicMarkdown";
import { ElementSubmissionNav } from "@/lms/element/ElementSubmissonNav";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { cn } from "@/lib/utils";
import { CorrectIndicator } from "@/lms/element/CorrectIndicator";
import { ElementTitle } from "@/lms/element/ElementTitle";

export default function MultipleChoice({
  element,
  children,
}: {
  element: ElementsResponse;
  children?: React.ReactNode;
}) {
  if (!element) return null;

  return (
    <div className="flex w-full justify-center">
      <div className="w-full max-w-3xl flex flex-col gap-2 ">
        <ElementTitle icon={CircleHelp} title={"Multiple Choice"}>
          {children}
        </ElementTitle>
        <div className="flex flex-col gap-6 bg-accent/40 px-4 py-4 rounded-md border border-primary/50">
          <CodeHike item={element} code={element.data.question} />

          <OptionsForm element={element} />
        </div>
      </div>
    </div>
  );
}

export function getCorrectAnswer(correctAnswer: number | null) {
  return correctAnswer != null && correctAnswer >= 0 ? correctAnswer : -10000;
}

function OptionsForm({ element }: { element: ElementsResponse }) {
  const data = element.original ? element.original.data : element.data;
  const user = useUser();
  const enrollment = useEnrollment();
  const { data: submission } = useSubmissionDetail(
    element.id,
    element.sectionId,
    enrollment?.id,
    element.submissionRequired,
  );
  const { submit, isPending: submitPending } = useSubmit(
    element.id,
    element.sectionId,
    enrollment?.id,
  );

  const formOptions = {
    // defaultValues: { response: -1 },
    defaultValues: { response: submission ? submission.data?.response : -1 },
    //TODO: Create a defaultSubmit function
    onSubmit: async ({ value, formApi }: { value: any; formApi: any }) => {
      const data = {
        elementId: element.id,
        enrollmentId: enrollment?.id,
        attempts: submission ? submission.attempts + 1 : 1,
        teamId: enrollment?.teamId,
        name: user?.name,
        data: value,
        complete: correctAnswer >= 0 ? value.response == correctAnswer : true,
      };

      if (!submit) throw new Error("no submit function");
      const result = await submit(data, submission?.id);
      if (result) formApi.reset();
    },
  };

  const form = useForm(formOptions);
  const formResponse = form.useStore((state) => state.values.response);
  const correctAnswer = getCorrectAnswer(data?.correctAnswer);
  const submittedResponse = submission ? submission.data?.response : null;
  const dirty = formResponse != -1 && submittedResponse != formResponse;
  const correct = submission && submittedResponse == correctAnswer;

  const options = element.data.options;
  return (
    <Form form={form}>
      <fieldset>
        <RadioGroup
          value={formResponse.toString()}
          onValueChange={(value) =>
            form.setFieldValue("response", parseInt(value))
          }
        >
          {options.map((option, index: number) => (
            <label
              key={index}
              className={cn(
                "flex items-center gap-4 border rounded-lg p-2 px-4 hover:border-primary hover:bg-accent",
                index == submittedResponse ? "bg-muted-foreground/25" : "",
                index == formResponse ? "bg-primary/25 border-foreground" : "",
              )}
              htmlFor={`option-${index}-${element.id}`}
            >
              <RadioGroupItem
                value={index.toString()}
                id={`option-${index}-${element.id}`}
              />
              <BasicMarkdown code={option.text} />
            </label>
          ))}
        </RadioGroup>
        <ElementSubmissionNav
          item={element}
          form={form}
          disabled={submitPending || !dirty}
          submission={submission}
          hint={element.data.hint}
          className="relative mt-3"
        >
          {!dirty && correctAnswer >= 0 && submission && (
            <CorrectIndicator correct={correct}>
              <BasicMarkdown
                className="ps-6"
                code={options[submittedResponse].explanation}
              />
            </CorrectIndicator>
          )}
        </ElementSubmissionNav>
      </fieldset>
    </Form>
  );
}

// function SubmittedResponse({ response }: { response: string }) {
//   return (
//     <div className="mb-2">
//       <div className="border px-2 pt-1 pb-2 bg-background/75 rounded-md text-sm">
//         <BasicMarkdown className="ps-2 tracking-tight" code={response} />
//       </div>
//     </div>
//   );
// }
