//admin
//- fetch all submission from an element by class  (need to store class info? aka enrollment?)
// - fetch all submission (not full just count) the number of submission by course(all classes)
//

import { queryOptions, useQuery } from "@tanstack/react-query";
import {
  useAddMutation,
  useDeleteMutation,
  useEditMutation,
} from "./mutations";
import {
  RecordIdString,
  SubmissionsRecord,
  SubmissionsResponse,
} from "./types";
import { getFullList, getFirst, getGroupList, getFullMap } from ".";
import { DEFAULT_STALETIME } from "./lms";
import { useEffect, useState } from "react";
import { useSectionProgress, updateSectionProgress } from "../store";
import { useEditSectionProgressMutation } from "./section-progress";

export function useSubmit(
  elementId: string | null | undefined,
  sectionId: string | null | undefined,
  enrollmentId: string | null | undefined,
) {
  const sectionProgress = useSectionProgress();
  const [isPending, setIsPending] = useState(false);
  const { mutateAsync: add, isPending: addPending } = useAddSubmissionMutation(
    elementId,
    sectionId,
    enrollmentId,
    sectionProgress,
  );
  const { mutateAsync: edit, isPending: editPending } =
    useEditSubmissionMutation(
      elementId,
      sectionId,
      enrollmentId,
      sectionProgress,
    );
  // if (!elementId || !enrollmentId) return null;

  async function submit(data: any, submissionId: string | null | undefined) {
    if (editPending || addPending) return;
    setIsPending(true);
    let res;
    // if Submission exists we edit the submission
    if (submissionId) {
      res = await edit({ id: submissionId, data }).catch((e) =>
        console.error(e),
      );
    } else {
      res = await add({ data }).catch((e) => console.error(e));
    }
    setIsPending(false);
    return res;
  }

  return { submit, isPending };
}

export const useAddSubmissionMutation = (
  elementId: string | null | undefined,
  sectionId: string | null | undefined,
  enrollmentId: string | null | undefined,
  sectionProgress: any,
) =>
  useAddMutation<SubmissionsRecord, SubmissionsResponse>("submissions", [
    // submissionListQuery(sectionId).queryKey,
    submissionDetailQuery(elementId, sectionId, enrollmentId, sectionProgress)
      .queryKey, //this triggers playgroud reload
  ]);

export const useEditSubmissionMutation = (
  elementId: string | null | undefined,
  sectionId: string | null | undefined,
  enrollmentId: string | null | undefined,
  sectionProgress: any,
) =>
  useEditMutation<SubmissionsRecord, SubmissionsResponse>("submissions", [
    //submissionListQuery(sectionId).queryKey,
    submissionDetailQuery(elementId, sectionId, enrollmentId, sectionProgress)
      .queryKey,
  ]);

export const useDeleteSubmissionMutation = (elementIds: RecordIdString[]) =>
  useDeleteMutation("submissions", [
    submissionSectionPartialQuery(elementIds).queryKey,
  ]);

//do not store section id and lesson id and unit id in the submission
//      elements can be moved and then you would need to update all the submission

// Storing

//user
// fetch sigular submission
// fetch submsiions for all elements in a lesson?

// id, enrollmentId:{courseId, classId  userId, teamId}

// SUBMISSIONS

// submissionElementQuery - get all submission for an Element from a User

//USER VS ADMIN QUERIES?
//const submissionsFields = "id, elementId, enrollmentId, updated";

//Fetch all of users submissions from a specific section
// would need to add useUser
// however when user submits a submission it would invalidate the list
// then that would re-fetch the list of all submissions...
// export const submissionElementPartialQuery = (elementId: RecordIdString) =>
//   queryOptions({
//     queryKey: ["submissions", "element", "partial", { elementId }],
//     queryFn: () =>
//       getFullList<SubmissionsResponse>("submissions", {
//         filter: `elementId="${elementId}"`,
//         fields: submissionsFields,
//       }),
//     staleTime: DEFAULT_STALETIME,
//     refetchInterval: 1000 * 10,
//     meta: { errorMessage: "Failed to fetch submission list (partial)" },
//   });

//v2

// Use by AdminSubmissionFetcher - elementId only
export const submissionSectionPartialQuery = (
  elementIds: RecordIdString[],
  classId?: RecordIdString | undefined,
) => {
  const filter = `(${elementIds.map((id) => `elementId="${id}"`).join("||")})${classId ? `&& enrollmentId.classId="${classId}"&&enrollmentId.userId.role < 300` : ""}`;
  //const filter = `${elementIds.map((id) => `elementId="${id}"`).join("||")}`;
  
  return queryOptions({
    queryKey: ["submissions", "section", "partial", filter ],
    queryFn: () =>
      getGroupList<SubmissionsResponse>(
        "submissions",
        {
          filter,
          fields: "elementId",
        },
        "elementId",
      ),
    staleTime: DEFAULT_STALETIME,
    refetchInterval: 1000 * 30,
    meta: { errorMessage: "Failed to fetch submission list (partial)" },
    enabled: !!elementIds.length,
    // notifyOnChangeProps: []
  });
};

// Admin fetch - all submission for an element also used for team submission view
export const submissionElementQuery = (
  elementId: RecordIdString,
  classId?: RecordIdString | undefined,
  teamId?: RecordIdString | undefined,
) => {
  const filter = `elementId="${elementId}"${classId ? `&&enrollmentId.classId="${classId}"` : ""}${teamId ? `&&teamId="${teamId}"` : ""}`;
  // console.log("test====",filter)
  return queryOptions({
    queryKey: [
      "submissions",
      "element",
      "full",
      { elementId, classId, teamId },
    ],
    queryFn: () =>
      getFullMap<SubmissionsResponse>(
        "submissions",
        {
          filter,
          fields:
            "id, collectionName, enrollmentId, teamId, name, attempts, data, correct, updated, file",
        },
        "enrollmentId",
      ),
    //staleTime: DEFAULT_STALETIME,
    staleTime: Infinity,
    refetchOnWindowFocus: false, // prevents user input from clearing
    refetchOnReconnect: false,
    refetchOnMount: "always",
    meta: { errorMessage: "Failed to fetch element submission (full)" },
  });
};

//do we get all submission for the user to get the submission id?

// THIS SEEMS BETTER
//fetchFirstFn and  const filter = `enrollmentId="${enrollment?.id}"&&elementId="${elementId}"`
// or fetch all submissionIds?
export const submissionDetailQuery = (
  elementId: string | null | undefined,
  sectionId: string | null | undefined,
  enrollmentId: string | null | undefined,
  sectionProgress: any,
) =>
  queryOptions({
    queryKey: ["submissions", "detail", enrollmentId, elementId],
    queryFn: () =>
      getFirst<SubmissionsResponse>(
        "submissions",
        `enrollmentId="${enrollmentId}"&&elementId="${elementId}"`,
      ),
    staleTime: Infinity,
    refetchOnWindowFocus: false, // prevents user input from clearing
    refetchOnReconnect: false,
    refetchOnMount: "always",
    retry: false,
    meta: { errorMessage: "Failed to fetch element submission" },
    enabled:
      !!elementId &&
      !!enrollmentId &&
      !!sectionProgress &&
      sectionId == sectionProgress.sectionId,
  });

export function useSubmissionDetail(
  elementId: string,
  sectionId: string,
  enrollmentId: string | null | undefined,
  submissionRequired: boolean,
) {
  const sectionProgress = useSectionProgress();

  const query = useQuery(
    submissionDetailQuery(elementId, sectionId, enrollmentId, sectionProgress),
  );

  const { data: submission, isPending } = query;
  const { mutateAsync: edit } = useEditSectionProgressMutation(enrollmentId);

  const onChange = async (complete: boolean) => {
    if (!sectionProgress) return;

    if (!enrollmentId || !sectionProgress) return;
    if (sectionId != sectionProgress?.sectionId) return;
    const data = { sectionId, enrollmentId, complete };

    if (sectionProgress?.id) {
      // console.log("^^^^^EDITING SECTION PROGRESS - submit^^^^^", sectionProgress, complete);
      await edit({ id: sectionProgress.id, data }).catch((e) =>
        console.error(e),
      );
    }
  };

  useEffect(() => {
    if (!submissionRequired) return;
    if (submission) {
      updateSectionProgress(elementId, submission.complete, onChange);
    }
  }, [submission]);

  useEffect(() => {
    if (!submissionRequired) return;
    if (sectionId != sectionProgress?.sectionId) return;
    if (!submission && !isPending) {
      //no submission found - new element??
      if (sectionProgress && sectionProgress.complete) {
        onChange(false);
      }
    }
  }, [sectionProgress]);

  return query;
}

//  BELOW IS CURRENTLY NOT IN USE Nov 4th
// fetch all user submission based on enrollmentId
// (acts as a table to look up all submissionId to look up the submission))
// - if user add a submission refetch entire user sub list???
// --- unless UPDATE QUERY CACHE, don't refetch
// - then recalculate progress on entire tree???
export const submissionsUserQuery = (enrollmentId: RecordIdString) =>
  queryOptions({
    queryKey: ["submissions", "user"],
    queryFn: () =>
      getFullList<SubmissionsResponse>("submissions", {
        filter: `enrollmentId="${enrollmentId}"`,
        fields: submissionsFields, //no data...
      }),
    staleTime: DEFAULT_STALETIME,
    meta: { errorMessage: "Failed to fetch user submissions" },
  });
