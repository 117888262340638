import { Search, type LucideIcon } from "lucide-react";

import { matchSorter } from "match-sorter";
import { Input, Form, zodValidator } from "@/components/form";
//import { Input } from "@/components/ui/input";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Separator } from "@/components/ui/separator";
import { useSearchResults } from "@/lib/store";
import { useEffect, useState } from "react";
import { BasicMarkdown } from "../format/BasicMarkdown";
import { useNavigate } from "@tanstack/react-router";
import { useForm } from "@tanstack/react-form";
import { GenericInputField } from "../form/CustomFields";
//import { zodValidator } from "@tanstack/zod-form-adapter";

//const itemRank = rankItem(row.getValue(columnId), value);

export function SearchButton() {
  // const isMobile = useIsMobile();
  const allResults = useSearchResults();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  function search() {
    if (!allResults) return;
    const res = matchSorter(allResults, searchQuery, {
      keys: ["index", "name"],
    });
    if (res) setSearchResults(res);
  }

  const form = useForm({
    validatorAdapter: zodValidator(),
    defaultValues: {
      search: "",
    },
    onSubmit: async () => {
      if (searchResults && searchResults[0]) {
        navigate({ to: searchResults[0].url });
        setOpen(false);
      }
    },
  });

  useEffect(() => {
    // Set a timeout to delay calling the search function
    const handler = setTimeout(() => {
      if (searchQuery.length > 0) {
        search();
      }
    }, 200);

    // Clear the timeout if searchQuery changes before 200ms
    return () => clearTimeout(handler);
  }, [searchQuery]);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger
        className="min-w-8 flex h-5 justify-center items-center gap-2  rounded-full p-1.5 text-sm font-medium outline-none ring-ring transition-all hover:bg-accent hover:text-accent-foreground focus-visible:ring-2 data-[state=open]:bg-accent data-[state=open]:text-accent-foreground border border-foreground"
        onClick={() => setOpen(true)}
      >
        <Search className="h-4 w-4 shrink-0" />
        <div className="flex overflow-hidden ">
          <div className="line-clamp-1 text-xs">Search</div>
        </div>
      </PopoverTrigger>
      <PopoverContent
        side="bottom"
        align="start"
        sideOffset={4}
        className="w-96 p-0 border-primary"
      >
        <div className="border-b p-2.5">
          <Form form={form}>
            <GenericInputField
              form={form}
              className="h-8 rounded-sm shadow-none focus-visible:ring-0"
              name={"search"}
              type="search"
              onChange={(e) => setSearchQuery(e.target.value)}
              autoComplete="off"
            />
            {/* <Input
              type="search"
              placeholder="Search..."
              className="h-8 rounded-sm shadow-none focus-visible:ring-0"
              onChange={(e) => setSearchQuery(e.target.value)}
            /> */}
          </Form>
        </div>

        <div className="grid gap-1 p-1.5 text-sm ">
          {searchResults?.map((result, key) => {
            //const [index, name] = result.split(", ");
            if (key > 8) return;
            return (
              <div
                key={key}
                className="rounded-md p-2.5 outline-none ring-ring hover:bg-accent hover:text-accent-foreground focus-visible:ring-2 flex gap-4 items-center cursor-pointer"
                onClick={() => {
                  navigate({ to: result.url });
                  setOpen(false);
                }}
              >
                <div className="font-bold w-12 text-end">{result.index}</div>
                <div className="line-clamp-1 max-w-64">
                  <BasicMarkdown code={"##### " + result.name} />
                </div>
              </div>
            );
          })}

          <Separator className="my-1.5" />
          <div className="rounded-md px-2.5 py-1 text-muted-foreground outline-none">
            {searchResults?.length ? searchResults.length : 0} results
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
}
