import { Cloud, CloudUpload, } from "lucide-react";
import { Header } from "@/components/format/Dialog";
import { useClassFilter, useCourse, useUser } from "@/lib/store";
import { allUsersFilesListQuery } from "@/lib/pb/files";
import { useQuery } from "@tanstack/react-query";
import { UserFilesTable } from "../files/FilesTable";

import { TooltipSheetButton } from "@/components/format/Buttons";
import { ScrollArea } from "@/components/ui/scroll-area";
import { ClassFilter } from "../classes/ClassFilter";

export function UserFilesButton({ className }: { className: string }) {
 
  const course = useCourse();
  const cls = useClassFilter();
  const {
    data: files,
    isPending,
    error,
    refetch,
    ...eQuery
  } = useQuery(allUsersFilesListQuery(course.id, cls?.id));

  return (
    <TooltipSheetButton
      icon={Cloud}
      info="User Files"
      variant="ghost"
      showInfo={true}
      className={className}
      width="max-w-7xl"
    >
      <div className="flex flex-col max-w-7xl h-screen gap-6">
        <Header title={<Title />} />
        <ScrollArea className="h-[87dvh]">
          <div className="flex flex-col w-full gap-6">
            <UserFilesTable
              files={files ? files : []}
              refetch={refetch}
              isPending={isPending}
              showUser={true}
            />
          </div>
        </ScrollArea>
      </div>
    </TooltipSheetButton>
  );
}

function Title() {
  return (
    <div className="flex gap-4 items-center text-2xl">
      <CloudUpload className="h-6 w-6" /> User Files
       <ClassFilter className="text-sm ms-10" />
    </div>
  );
}
