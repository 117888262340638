import { SandpackFileExplorer as FullFileExplorer } from "sandpack-file-explorer";
import { SandpackFileExplorer } from "@codesandbox/sandpack-react";
import { Button } from "@/components/ui/button";
import { useState } from "react";
import { ChevronsLeft, ChevronsRight, FolderCode, Ellipsis } from "lucide-react";

export function PlaygroundFileExplorer({
  canEditFiles,
}: {
  canEditFiles: boolean;
}) {
  const [isEditing, setIsEditing] = useState(canEditFiles);
  const [hidden, setHidden] = useState(!canEditFiles);

  if (hidden) {
    return (
      <Button
        variant="ghost"
        className="h-10 px-1 sp-c-bxeRRt "
        onClick={() => setHidden(false)}
      >
        <div className="flex gap-0">
          <FolderCode className="p-0 m-0" />
          <ChevronsRight className="p-0 m-0" />
        </div>
      </Button>
    );
  }

  return (
    <div className="flex flex-col border-e-2 w-[200px] border border-primary">
      {!canEditFiles && (
        <div className="flex h-10 sp-c-dyHYiL">
          <Button
            variant="ghost"
            className="h-10 px-2 sp-c-bxeRRt"
            onClick={() => {
              setHidden(true);
              setIsEditing(false);
            }}
          >
            <div className="flex gap-0">
              <ChevronsLeft className="p-0 m-0" />
              <FolderCode className="p-0 m-0" />
            </div>
          </Button>
          {!isEditing && (
            <Button
              variant="ghost"
              size="sm"
              className="h-10 sp-c-bxeRRt ms-auto"
              onClick={() => setIsEditing(!isEditing)}
            >
              <Ellipsis />
            </Button>
          )}
        </div>
      )}
      <div className="flex h-full ">
        {isEditing ? <FullFileExplorer /> : <SandpackFileExplorer />}
      </div>
    </div>
  );
}

// <div className="flex flex-col-reverse w-full h-full ">
//   <div className="flex h-6 p-2">files</div>
//   <div className="flex h-full ">
//     <SandpackFileExplorer autoHiddenFiles />
//   </div>
// </div>
