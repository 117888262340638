import { getLanguageName } from "@/lib/lang";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";

export function TranslationReset({
  languages,
  reset,
}: {
  languages: string[];
  reset: () => any;
}) {
  const border = "grid gap-1 border border-muted-foreground rounded-lg px-2 h-12";
  if (!languages || languages.length == 0)
    return <div className={cn(border, "text-xs items-center")}>No translations</div>;

  return (
    <div className={border}>
      <div className="text-xs">Translated to</div>
      <div className="flex gap-2">
        {languages?.map((lang) => (
          <div key={lang} className="h-5 text-xs border rounded-full border-success px-2">
            {getLanguageName(lang)}
          </div>
        ))}
        <Button
          variant="outline"
          className="h-5 text-xs rounded-full"
          onClick={reset}
        >
          Reset
        </Button>
      </div>
    </div>
  );
}