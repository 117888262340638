import { FullDialogButton, TooltipButton } from "@/components/format/Buttons";
import { ElementsResponse, SubmissionsResponse } from "@/lib/pb/types";
import { ROLES } from "@/lib/roles";
import { useUser } from "@/lib/store";
import { Inbox, RotateCw, MessageSquare } from "lucide-react";
import { ClassFilter } from "@/lms/classes/ClassFilter";
import { ElementSubmissionsDialog } from "@/lms/element/ElementSubmissionsDialog";
import { useMemo } from "react";
import {
  commentsQueryAtom,
  submissionsQueryAtom,
} from "../section/SectionContents";
import { useAtom } from "jotai";

interface SubmissionNavProps {
  element: ElementsResponse;
}

export function ElementSubmissionAdminNav(props: SubmissionNavProps) {
  const user = useUser();
  const isCoach = user?.role >= ROLES.coach;
  if (!isCoach) return;

  return (
    <div className="flex items-center">
      <ClassFilter />
      <SubmissionsButton {...props} />
    </div>
  );
}

function SubmissionsButton({ element }: SubmissionNavProps) {
  //set state
  return (
    <div className="flex items-center">
      <RefetchButton element={element} />
      <FullDialogButton
        label={<SubmissionButtonLabel element={element} />}
        variant="outline"
        contents={ElementSubmissionsDialog}
        className="h-7 w-24 text-primary border-primary"
        item={element}
      />
    </div>
  );
}

function RefetchButton({ element }: SubmissionNavProps) {
  const [query] = useAtom(submissionsQueryAtom);
  const [commentsQuery] = useAtom(commentsQueryAtom);
  const { isPending, refetch } = query || {};
  const { isPending: isCommentsPending, refetch: refetchComments } =
    commentsQuery || {};

  return (
    <TooltipButton
      variant="ghost"
      className="p-2 h-7 text-muted-foreground"
      onClick={() => {
        if (refetchComments) refetchComments();
        if (refetch) refetch();
      }}
      disabled={isPending}
      info="Refresh Submissions"
    >
      <RotateCw className="h-4 w-4" />
    </TooltipButton>
  );
}

function SubmissionButtonLabel({ element }: SubmissionNavProps) {
  const [query] = useAtom(submissionsQueryAtom);
  const [commentsQuery] = useAtom(commentsQueryAtom);
  const { data: submissions, isPending, refetch } = query || {};
  const { data: comments } = commentsQuery || {};
  const items = useMemo(
    () => (submissions ? submissions.get(element.id) : []),
    [submissions, element],
  );

  const commentItems = useMemo(
    () => (comments ? comments.get(element.id) : []),
    [comments, element],
  );
 // console.log(items)
  const isFeedback = element.type == "feedback";

  return (
    <div className="flex gap-2 items-center">
      {!isFeedback && (
        <div className="flex gap-2 items-center">
          <Inbox className="h-4 w-4" />
          {items ? items.length.toString() : isPending ? "..." : "0"}
        </div>
      )}
      {(commentItems || isFeedback) && (
        <div className="flex gap-2 items-center">
          <MessageSquare /> {commentItems ? commentItems.length : 0}
        </div>
      )}
    </div>
  );
}
