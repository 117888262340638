import { Link } from "@tanstack/react-router";
import { BasicMarkdown } from "@/components/format/BasicMarkdown";
import { cn } from "@/lib/utils";
export function TableOfContents({ section, items }) {
  const lesson = section.parent;
  return (
    <div className="flex flex-col  me-4 mt-6">
      <div className="grid gap-1">
        <div className="text-xs text-muted-foreground">Table of Contents</div>
        {/* <div className="text-sm text-muted-foreground -ms-4">
          {lesson?.parent?.name}
        </div>
        <div className=" text-md tracking-tight">{lesson?.name}</div> */}
        {/* <div className="font-bold text-lg tracking-tight">{section?.name}</div> */}
        <Link
          to={`#section-top`}
          className={cn("hover:bg-secondary flex-0 p-1 ")}
        >
          <BasicMarkdown
            className="tracking-tight leading-tight line-clamp-1 font-bold "
            code={section?.name}
          />
        </Link>
      </div>
      <div>
        {items.map((item) => (
          <ToCElement key={item.id} item={item} />
        ))}
      </div>
    </div>
  );
}

function ToCElement({ item }) {
  const headings = item.compiled?.data?.headings;
  if (!headings) return null;

  return (
    <nav>
      <div className="flex flex-col border-s-2">
        {headings.map((heading) => (
          <Link
            key={heading.id}
            to={`#${heading.id}`}
            className={cn("p-1 px-2 hover:bg-secondary flex-0 h-7.5")}
            style={{
              marginLeft: (heading.depth - 1) * 14,
              fontSize: 16 - heading.depth + "px",
              fontWeight: (7 - heading.depth) * 100,
            }}
          >
            <BasicMarkdown
              className="tracking-tight leading-tight line-clamp-1 "
              code={heading.text}
            />
          </Link>
        ))}
      </div>
    </nav>
  );
}
