import { useQuery } from "@tanstack/react-query";
import { submissionHistoryQuery } from "@/lib/pb/submission-history";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuItem,
} from "@/components/ui/dropdown-menu";
import { Badge } from "@/components/ui/badge";
import { format, formatRelative } from "date-fns";
import { ChevronDown } from "lucide-react";
import { Button } from "@/components/ui/button";

export function SubmissionHistory({
  submissionId,
  changeFiles,
  dirty,
}: {
  submissionId?: string;
  changeFiles: (newFiles: any) => void;
  dirty?: boolean;
}) {
  const { data: history, isPending: historyPending } = useQuery(
    submissionHistoryQuery(submissionId),
  );
  //console.log(">>>>>>",history)

  if (!history) return;

  return (
    <div>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="outline" className="text-muted-foreground pe-1 h-8">
            History <Badge className="p-1 h-4">{history?.length}</Badge>{" "}
            <ChevronDown />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          {/* <DropdownMenuItem onClick={() => {}}>
            <div className="font-bold">default</div>
          </DropdownMenuItem> */}
          {history ? (
            history.map((item) => (
              <DropdownMenuItem
                key={item.id}
                onClick={() => changeFiles(item.data.files)}
              >
                Version {item.version} -{" "}
                {formatRelative(item.created, new Date())}
              </DropdownMenuItem>
            ))
          ) : (
            <DropdownMenuItem>Loading...</DropdownMenuItem>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}
