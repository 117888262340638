import { Widths, Heights } from "@/lib/sizes";
import { ElementsResponse } from "@/lib/pb/types";
import CodeHike from "@/components/codehike/CodeHike";
import { cn, Direction } from "@/lib/utils";

export default function YouTube({ element }: { element: ElementsResponse }) {
  const data = element.original ? element.original.data : element.data;

  //can not be translated (use original)
  const width = data?.width || "md";
  const height = data?.height || "md";
  const placement = data?.placement || "right";
  
  const url = element.data?.url;
  if (!url) return null;
  return (
    <div className="w-full">
      <div
        className={cn(
          "flex max-w-3xl mx-auto justify-center gap-6 ",
          Direction[placement as keyof typeof Direction],
        )}
      >
        <CodeHike item={element} code={element.data.description} />
        <YouTubeEmbed
          width={width}
          height={height}
          url={url}
          startTime={element.data?.startTime}
          endTime={element.data?.endTime}
        />
      </div>
    </div>
  );
}

function checkUrl(url: string) {
  if(url.startsWith("https://youtu.be/")) {
    return url.replace("https://youtu.be/", "https://www.youtube.com/embed/")
  }
  if(url.startsWith("https://www.youtube.com/watch?v=")) {
    return url.replace("https://www.youtube.com/watch?v=", "https://www.youtube.com/embed/")
  }
  if(!url.startsWith("https://")) {
    return `https://www.youtube.com/embed/${url}`
  }
  return url
}

export function YouTubeEmbed({
  width = "full",
  height = "full",
  url,
  className,
  startTime,
  endTime,
}: {
  width?: string;
  height?: string;
  url: string;
  className?: string;
  startTime?: number;
  endTime?: number;
}) {
  if (!url) return;
  const embedUrl = checkUrl(url)

  const start = startTime ? `&start=${startTime}` : "";
  const end = endTime ? `&end=${endTime}` : "";

  return (
    <iframe
      className={cn(Widths[width], className)}
      id="youtube-embed"
      width="100%"
      height={Heights[height]}
      src={`${embedUrl}?autoplay=0&controls=1&rel=0${start}${end}`}
      title="YouTube video player"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen={true}
      credentialless="true"
    ></iframe>
  );
}
