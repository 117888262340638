import { createFileRoute } from "@tanstack/react-router";
import { CourseDetails2 } from "@/lms/course/CourseCard";
import { UnitsList } from "@/lms/unit/UnitsList";
import { useClass, useCourse, useUser, useTeam } from "@/lib/store";
import { ROLES } from "@/lib/roles";
import NavBar from "@/components/nav/NavBar";
import { Clock, Pencil } from "lucide-react";
import { FullDialogButton } from "@/components/format/Buttons";
import { CourseEditForm } from "@/lms/course/CourseAdminForms";
import { ClassEditForm } from "@/lms/classes/ClassAdminForm";
import { Footer } from "@/components/nav/Footer";
import { ClassDetails } from "@/lms/classes/ClassDetails";

export const Route = createFileRoute("/_auth/course/$courseId/")({
  component: CourseComponent,
});

export class CourseNotFoundError extends Error {}

function CourseComponent() {
  const user = useUser();
  const course = useCourse();
  const cls = useClass();
  const team = useTeam();

  if (!course) {
    return <div>No course</div>;
  }
  const isAdmin = user?.role === ROLES.administrator;

  return (
    <>
      <NavBar />

      <main className="grid flex-1 items-start gap-8 sm:py-0 md:gap-4 sm:grid-cols-3 xl:gap-8 2xl:px-12 ">
        <div className="grid gap-6">
          {course && <UnitsList isAdmin={isAdmin} units={course.children} />}
        </div>

        <div className="grid auto-rows-max items-start gap-2 md:gap-4 xl:gap-6 sm:col-span-2">
          <div className="relative border rounded-xl p-6 grid shadow-md bg-accent">
            <div className="flex text-xl font-bold tracking-tight items-center gap-4">
              <Clock />
              {cls ? cls.name : "No enrollment"}
            </div>
            {cls && <ClassDetails item={cls} className="pt-4" teamName={team?.name}/>}
           
            {isAdmin && (
              <div className="absolute top-0 right-0">
                <FullDialogButton
                  icon={Pencil}
                  item={cls}
                  contents={ClassEditForm}
                  variant="ghost"
                  className="h-6 px-0 w-6 "
                />
              </div>
            )}
          </div>
          {!course ? (
            <div>No course found</div>
          ) : (
            <div className="relative">
              <CourseDetails2 course={course} />
              {isAdmin && (
                <div className="absolute top-0 right-0">
                  <FullDialogButton
                    icon={Pencil}
                    item={course.original || course}
                    contents={CourseEditForm}
                    variant="ghost"
                    className="h-6 px-0 w-6 "
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </main>
      <Footer />
    </>
  );
}
