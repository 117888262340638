import { createFileRoute, Navigate } from "@tanstack/react-router";
import { CoursesList } from "@/lms/course/CoursesList";
import { Button } from "@/components/ui/button";
import { useUser } from "@/lib/store";
import { useStaticTranslations } from "@/lib/lang";
import { Link } from "@tanstack/react-router";
import { GoogleLogInButton } from "@/components/auth/GoogleLoginButton";
import { Mail } from "lucide-react";

export const Route = createFileRoute("/_nav/start")({
  // loader: (opts) =>
  //   opts.context.queryClient.ensureQueryData(coursesListQuery()),
  component: Start,
});

export function Start() {
  const user = useUser();
  if (user) {
    return <Navigate to="/" />;
  }
  return (
    <div className="grid gap-4 px-4 md:px-6 xl:px-16">
      <HeroSection />
      <CoursesList />
    </div>
  );
}

function HeroSection() {
  const st = useStaticTranslations();
  return (
    <section className="relative w-full max-w-7xl min-h-[350px] bg-gradient-to-r from-primary to-destructive mx-auto">
      <div className="relative z-10 container h-full flex flex-col items-center justify-center px-4 md:px-6 text-center text-primary-foreground">
        <h1 className="text-4xl font-bold tracking-tighter sm:text-5xl md:text-6xl lg:text-7xl">
          Welcome to the Foundation Class Site
        </h1>
        <p className="max-w-3xl mt-4 text-lg md:text-xl">
          Sign up for a free account to get started and then enroll in a course.
        </p>
        <div className="mt-8 flex gap-4">
          <Link to="/signup" className="[&.active]:font-bold ">
            <Button className="text-md font-bold px-6">
              <Mail/> {st("Sign up now")}
            </Button>
          </Link>
          <GoogleLogInButton isSignUp={true}/>
        </div>
      </div>
    </section>
  );
}
