import { queryOptions } from "@tanstack/react-query";
import { useAddMutation, useDeleteMutation } from "./mutations";
import { UserFilesRecord, UserFilesResponse } from "./types";
import { getFullList } from ".";
import { DEFAULT_STALETIME } from "./lms";

export const useAddFileMutation = (userId: string) =>
  useAddMutation<UserFilesRecord, UserFilesResponse>("user_files", [
    userFilesListQuery(userId).queryKey, //this triggers playground reload
    ["user_files", "all"]
  ]);

export const useDeleteUserFileMutation = (userId: string) =>
  useDeleteMutation("user_files", [
    userFilesListQuery(userId).queryKey,
    ["user_files", "all"],
  ]);

export const userFilesListQuery = (userId: string) =>
  queryOptions({
    queryKey: ["user_files", "list", userId],
    queryFn: () =>
      getFullList<UserFilesResponse>("user_files", {
        filter: `enrollmentId.userId="${userId}"`,
        // expand: "classId",
        sort: "-updated",
      }),
    staleTime: DEFAULT_STALETIME,
    meta: { errorMessage: "Failed to fetch user files" },
  });

export const allUsersFilesListQuery = (courseId: string, classId: string) =>
  queryOptions({
    queryKey: ["user_files", "all", { courseId, classId }],
    queryFn: () =>
      getFullList<UserFilesResponse>("user_files", {
        filter: classId
          ? `enrollmentId.classId="${classId}"`
          : `enrollmentId.courseId="${courseId}"`,
        expand: "userId",
        sort: "-updated",
      }),
    staleTime: DEFAULT_STALETIME,
    meta: { errorMessage: "Failed to fetch ALL users files" },
  });
