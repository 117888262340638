import CodeHike from "@/components/codehike/CodeHike";
import { DialogButton } from "@/components/format/Buttons";
import { Header } from "@/components/format/Dialog";
import { ScrollArea } from "@/components/ui/scroll-area";
import { List } from "lucide-react";

export function InstructionsTrigger({ instructions }: { instructions: string }) {
  return (
    <DialogButton
      icon={List}
      label="Instructions"
      variant="ghost"
      contents={InstructionsContent}
      className="ms-auto h-9 text-muted-foreground text-md hover:bg-success rounded-full border border-success"
      item={instructions}
      preventDefault={true} //hint button is in the form, need to prevent default
    />
  );
}
export function InstructionsContent({ item: code }: { item: string }) {
  return (
    <ScrollArea className="h-[87dvh]">
      <Header
        title={
          <div className="flex text-success items-center gap-2 text-2xl">
            <List className="h-5 w-5" /> Instructions
          </div>
        }
      />
      <div className="flex flex-col">
        <CodeHike code={code} />
      </div>
    </ScrollArea>
  );
}
