import {
  CommentsResponse,
  ElementsResponse,
  SubmissionsResponse,
} from "@/lib/pb/types";
import { cn } from "@/lib/utils";
import ElementAdminMenu from "../element/ElementAdminMenu";
import Elements from "@/lms/elements/Elements";
import { ElementSubmissionAdminNav } from "../element/ElementSubmissionAdminNav";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { submissionSectionPartialQuery } from "@/lib/pb/submissions";
import { commentSectionPartialQuery } from "@/lib/pb/comment";
import { useQuery } from "@tanstack/react-query";
import { useClassFilter, useUser } from "@/lib/store";
import { NotepadText } from "lucide-react";

export default function SectionContents({
  sectionId,
  items,
}: {
  sectionId: string;
  items: ElementsResponse[];
}) {
  const user = useUser();
  const isAdmin = user?.role === ROLES.administrator;
  const isCoach = user?.role >= ROLES.coach;

  // if (items.length < 1) {
  //   return <div className="flex w-full justify-center">No elements found</div>;
  // }

  /* old api request 
  @request.auth.role >= 300|| @request.auth.id = enrollmentId.userId || (
  @request.auth.role >= 200  &&
  @collection.enrollments.id ?= enrollmentId &&
  @collection.enrollments:auth.userId ?= @request.auth.id &&
  @collection.enrollments.teamId ?= @collection.enrollments:auth.teamId)
*/
  const submitItems = useMemo(
    () => items.filter((item) => item.isSubmitType),
    [items, sectionId],
  );

  return (
    <div className="grid gap-6 w-full ">
      {isCoach && (
        <AdminSubmissionFetcher sectionId={sectionId} items={submitItems} />
      )}
      {/* <RunTest /> */}

      {items?.map((element) => (
        <div
          id={"element-" + element.id}
          key={element.id}
          className={cn(
            `relative flex gap-4 border-2 border-transparent border-dashed py-1.5`,
            isAdmin ? "hover:border-primary/25 hover:rounded-md" : "",
            element.active ? "" : "opacity-50",
          )}
        >
          <Element element={element}>
            {isCoach && element.isSubmitType && (
              <ElementSubmissionAdminNav key={element.id} element={element} />
            )}
          </Element>
          {isAdmin && (
            <ElementAdminMenu
              item={element.original || element}
              items={items}
              size={5}
              className="absolute top-0 right-0"
            />
          )}
        </div>
      ))}
    </div>
  );
}

function RunTest() {
  const [info, setInfo] = useState("none"); //used in run test

  async function runTest() {
    const enrollments = await getFullMap(
      "enrollments",
      { expand: "userId" },
      "userId",
    );
    //console.log(enrollments);
    const list = await getFullList("user_files");
    setInfo(list?.length.toString());
    //list.length
    for (var i = 0; i < list.length; i++) {
      const item = list[i];
      const enrollment = enrollments.get(item.userId);
      console.log(enrollment);
      const res = await pbUpdate("user_files", item.id, {
        enrollmentId: enrollment?.id,
      });
      //console.log(item.id, res);
      setInfo(i.toString());
    }
  }
  return (
    <button className="border-4 border-red-500" onClick={runTest}>
      RUN TEST {info}
    </button>
  );
}

function capitalizeFirstLetter(string) {
  return string[0].toUpperCase() + string.slice(1);
}

function Element({
  element,
  ...props
}: {
  element: ElementsResponse;
  children?: React.ReactNode;
}) {
  const Component = Elements[element.type as keyof typeof Elements];
  if (!Component)
    return (
      <div className="w-full text-center">
        Unknown Element Type: {element.type}
      </div>
    );

  // NOTES for admin coaches
  if (element.access > 200 || element.active == false) {
    const title =
      element.access > 200
        ? capitalizeFirstLetter(RoleNames[element.access]) + " Note"
        : "Inactive Element: " + element.type;
    return (
      <div className="grid w-full ">
        <Accordion
          defaultOpen={false}
          title={
            <div className="flex gap-4 items-center text-muted-foreground">
              <NotepadText /> {title}
            </div>
          }
          className="mx-auto max-w-4xl bg-primary/10"
        >
          <Component element={element} {...props} />
        </Accordion>
      </div>
    );
  }
  return <Component element={element} {...props} />;
}

import { atom, useAtom } from "jotai";
import { RoleNames, ROLES } from "@/lib/roles";
import { getFullList, getFullMap, pbUpdate } from "@/lib/pb";
import { Accordion } from "@/components/codehike/Accordion";

//move the fetcher out!!! to prevent refetching on every render

interface SubmissionsQuery {
  data: Map<string, SubmissionsResponse[]> | undefined;
  isPending: boolean;
  refetch: () => void;
}
interface CommentsQuery {
  data: Map<string, CommentsResponse[]> | undefined;
  isPending: boolean;
  refetch: () => void;
}
export const submissionsQueryAtom = atom<SubmissionsQuery | null>(null);
export const commentsQueryAtom = atom<CommentsQuery | null>(null);

function AdminSubmissionFetcher({
  sectionId,
  items,
}: {
  sectionId: string;
  items: ElementsResponse[];
}) {
  const cls = useClassFilter();

  const [, setSubmissionsQuery] = useAtom(submissionsQueryAtom);
  const [, setCommentssQuery] = useAtom(commentsQueryAtom);

  const { data, isPending, refetch } = useQuery(
    submissionSectionPartialQuery(
      items.map((i) => i.id),
      cls?.id,
    ),
  );

  //console.log(data)
  const {
    data: commentsMap,
    isPending: isCommentsPending,
    refetch: refetchComments,
  } = useQuery(commentSectionPartialQuery(sectionId, cls?.id));

  useEffect(() => {
    if (!isPending) refetch();
  }, [cls]);

  useEffect(() => {
    setSubmissionsQuery({ data, isPending, refetch });
  }, [data, isPending, refetch, sectionId, cls]);

  useEffect(() => {
    if (commentsMap) {
      setCommentssQuery({
        data: commentsMap,
        isPending: isCommentsPending,
        refetch: refetchComments,
      });
    }
  }, [commentsMap, isCommentsPending]);

  return <div></div>;
}
