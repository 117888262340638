import {
  StepForward,
  ArrowUpToLine,
  ArrowDownToLine,
  SkipForward,
  FastForward,
} from "lucide-react";
import { Link } from "@tanstack/react-router";
import { useSection } from "@/lib/store";
import { cn } from "@/lib/utils";

const size = "w-2 h-2 md:w-3 md:h-3 lg:w-4 lg:h-4";

export function NavigateButtons() {
  const section = useSection();

  if (!section) return null;

  const { previous, next, url, parent: lesson } = section;
  const unit = lesson?.parent;
//hidden xl:grid 
  return (
    <div className="grid grid-cols-8 gap-2 lg:gap-4 text-muted-foreground mx-2 ">
      <TooltipLink
        info="Previous Unit"
        icon={FastForward}
        to={unit?.previous?.url}
        className={"rotate-180"}
        disabled={!unit?.previous}
      />
      <TooltipLink
        info="Previous Lesson"
        icon={SkipForward}
        to={lesson?.previous?.url}
        className={"rotate-180"}
        disabled={!lesson?.previous}
      />
      <TooltipLink
        info="Previous Section"
        icon={StepForward}
        to={previous?.url}
        className={"rotate-180"}
        disabled={!previous}
      />
      <TooltipLink info="Back to Top" icon={ArrowUpToLine} to={url} />
      <TooltipLink
        info="Jump to Bottom"
        icon={ArrowDownToLine}
        to={url}
        hash="#section-bottom"
      />
      <TooltipLink
        info="Next Section"
        icon={StepForward}
        to={next?.url}
        disabled={!next}
      />
      <TooltipLink
        info="Next Lesson"
        icon={SkipForward}
        to={lesson?.next?.url}
        disabled={!lesson?.next}
      />
      <TooltipLink
        info="Next Unit"
        icon={FastForward}
        to={unit?.next?.url}
        disabled={!unit?.next}
      />
    </div>
  );
}

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

export function TooltipLink({
  icon: Icon,
  to,
  info,
  disabled,
  hash = "#section-top",
  className,
  ...props
}: {
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  to: string;
  info: string;
  className?: string;
  disabled?: boolean;
  hash?: string;
}) {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Link to={to + hash} disabled={disabled} {...props}>
            <Icon
              className={cn(
                size,
                className,
                disabled ? "opacity-50 cursor-default" : "",
              )}
            />
          </Link>
        </TooltipTrigger>
        <TooltipContent>
          <p>{info}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
