import { Progress } from "@/components/format/Progress";
import Types from "@/lms/elements/Types";

export function ElementSubmissionsStats({ items, element }) {
  const { data, id, type } = element;
  const Type = Types[type as keyof typeof Types];
  var total = 0;
  var totalAttempts = 0;
  var totalStudents = 0;
  items.map((en: any) => {
    const role = en.expand.userId.role;
    if (role < 300) {
      totalStudents++
      if (en.submission && en.submission.attempts > 0) {
        totalAttempts += en.submission.attempts;
        total++;
      }
    }
  });

  const responseRate = percentage(total, totalStudents);
  const { index, name } = element.parent;

  return (
    <div className="flex px-2 gap-4">
      <div className="max-w-3xl w-full">
        {Type.SubmissionInfo && (
          <Type.SubmissionInfo items={items} element={element} />
        )}
      </div>

      <div className="grid grid-cols-2 gap-1 rounded-xl p-2 px-6 border-2 w-full max-w-xl items-center justify-center">
        <div className="text-end me-4">Section: </div>
        <div className="font-bold text-primary">
          {index} - {name}
        </div>

        <div className="text-end me-4">% Student Responded: </div>
        <Progress value={responseRate} />

        <div className="text-end me-4">Student responses: </div>
        <div>{total}</div>

        <div className="text-end me-4">Average attempts: </div>
        <div>{total && Math.round((totalAttempts / total) * 10) / 10}</div>

        <div className="text-end me-4">Submission required: </div>
        <div>{element && element.submissionRequired.toString()}</div>
      </div>
    </div>
  );
}

function percentage(count, total) {
  if (total == 0) return 0;
  return Math.round((count / total) * 1000) / 10;
}
